import offers from "./offers";

let kanastraUrl = `https://hub-sandbox.kanastra.com.br`;
let kanastraClientId = "67652789";
let kanastraClientSecret =
  "$2y$10$BIqpAicTPoim/TPDwnaiQOphApTNrCKgMwTMgDB/v1PyNfw4RSnzq";

var kanastraApi = {
  offers,

  async auth() {
    let url = `${kanastraUrl}/oauth/token`;
    let data = {
      grant_type: "client_credentials",
      client_id: `${kanastraClientId}`,
      client_secret: `${kanastraClientSecret}`,
      scope: "create-offers",
    };

    var res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default kanastraApi;
