<template>
  <v-container fluid pa-4>
    <v-card class="mx-auto" pa-0>
      <v-toolbar dark color="primary"
        ><h4>Invoice: {{ invCod }}</h4>

        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- <p>{{ invCod }}</p>
      <p>{{ filCod }}</p> -->

      <div v-for="(item, index) in items" :key="index">
        <v-card ma-0 pa-0>
          <v-toolbar pa-0 ma-0 style="background-color: lightgray">
            <b>Parcela: {{ item.pipCod }}</b></v-toolbar
          >
          <!-- <v-row style="margin: 0px">
            <v-col cols="6" class="ma-0 pa-0">
              <grid-field label="Comprador da moeda" :value="item.dpeNomPessoa">
              </grid-field>
            </v-col>

            <v-col cols="3" class="ma-0 pa-0">
              <grid-field label="Doc federal" :value="item.pdcDocFederalFmt">
              </grid-field>
            </v-col>

            <v-col cols="3" class="ma-0 pa-0">
              <grid-field
                label="Valor"
                :value="formatDecimal(item.pipMnyValor)"
              >
              </grid-field>
            </v-col>
          </v-row> -->

          <!-- <p>Vencimento</p> -->
          <v-card-text>
            <v-row style="margin: 0px">
              <v-col cols="4" class="ma-0 pa-0">
                <grid-field
                  label="Data de referência"
                  :value="formatTipoVencimento(item.pipVldTpVenc)"
                >
                </grid-field>
              </v-col>

              <v-col cols="2" class="ma-0 pa-0">
                <grid-field label="Dias" :value="item.pipNumDiasVcto">
                </grid-field>
              </v-col>

              <v-col cols="2" class="ma-0 pa-0">
                <grid-field
                  label="Data de vencimento"
                  :value="formatDate(item.dtaVcto)"
                >
                </grid-field>
              </v-col>
              <v-col cols="2" class="ma-0 pa-0">
                <grid-field label="Moeda" :value="invoice?.MOEDA_INV">
                </grid-field>
              </v-col>
              <v-col cols="2" class="ma-0 pa-0">
                <grid-field
                  label="Valor"
                  :value="formatDecimal(item.pipMnyValor)"
                >
                </grid-field>
              </v-col>
            </v-row>

            <v-row>
              <b class="ma-5">Contrato</b>
            </v-row>
            <v-row style="margin: 0px">
              <v-col cols="2" class="ma-0 pa-0">
                <grid-field label="Nº do Contrato" :value="item.pipNumOpCambio">
                </grid-field>
              </v-col>
              <v-col cols="2" class="ma-0 pa-0">
                <grid-field label="Banco" :value="item.pipNumBanco">
                </grid-field>
              </v-col>
              <v-col cols="2" class="ma-0 pa-0">
                <grid-field label="Praça" :value="item.pipNumPraca">
                </grid-field>
              </v-col>
              <v-col cols="3" class="ma-0 pa-0">
                <grid-field
                  label="Data Conclusão Pgto."
                  :value="formatDate(item.pipDtaConcPgto)"
                >
                </grid-field>
              </v-col>
              <v-col cols="3" class="ma-0 pa-0">
                <grid-field
                  label="Data Conclusão Pgto."
                  :value="formatDecimal(item.totalPago)"
                >
                </grid-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import conexosStrapiApi from "../../../../libraries/conexos/conexosStrapiApi";
import { mapState } from "vuex";
import GridField from "../../components/fields/GridField.vue";
import moment from "moment";

export default {
  name: "ProcessParcelas",
  props: ["invCod", "filCod", "invoice"], // object: quote, product ...+
  components: {
    GridField,
  },
  data() {
    return {
      nfses: [],
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 15,
      list: {},
      items: [],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "20px",
          sortable: true,
        },
        {
          text: "Prestador",
          value: "PrestadorRazaoSocial",
          align: "start",
          sortable: false,
        },
        {
          text: "Data emissão",
          value: "DataEmissao",
          align: "start",
          sortable: false,
        },
        { text: "Pdf link", value: "pdfLink", sortable: false },

        {
          text: "Valor Serviços",
          value: "ServicoValorServicos",
          sortable: false,
        },
        { text: "Número", value: "Numero", sortable: false },

        //PID_VLD_STATUS
        // { text: "Encomendante", value: "enterpriseName", sortable: false },

        // PrestadorRazaoSocial
        //     DataEmissao
        //     pdfLink
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  async mounted() {
    //await this.doSearch();
    //alert("mounted");

    let list = await conexosStrapiApi.log009ParcelasList(
      this.invCod,
      this.filCod
    );
    console.log(JSON.stringify({ list }));
    this.list = list;
    this.items = list?.rows?.sort((a, b) => a?.pipCod - b?.pipCod);
  },
  methods: {
    formatTipoVencimento(pipVldTpVenc) {
      let tipos = [
        "NÃO INFORMADO",
        "DA FATURA(INVOICE)",
        "DO BL OU AWS",
        "REGISTRO DA DI",
        "ANTECIPADO",
        "PREVISÃO CHEGADA",
      ];
      return tipos[pipVldTpVenc];
    },
    formatDate(value) {
      if (value) return moment(value + 3 * 60 * 60 * 1000).format("DD/MM/YYYY");
      else return "";
    },
    formatDecimal(value) {
      //  var d = Math.round(value * 100) / 100;
      return new Intl.NumberFormat("pt-BR", {
        //  currency: "BRL",
        // style: "currency",
        // minimumFractionDigits: 2,
      }).format(value || 0);
    },
  },
};
</script>

<style></style>
