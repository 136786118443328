<template>
  <v-app id="inspire">
    <v-container fluid>
      <p>Novo documento a pagar</p>
      <v-row>
        <v-col>
          <AutocompleteSelect
            label="Fornecedor"
            :items="providersNames"
            v-model="chipProvider"
          ></AutocompleteSelect>
        </v-col>

        <v-col>
          <AutocompleteSelect
            label="CNPJ"
            :items="federal_docs"
            v-model="chipDoc"
          ></AutocompleteSelect>
        </v-col>
        <v-col>
          <v-text-field v-model="com342.priCod" label="Processo">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="com342.filCod" label="Filial"> </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-simple-table>
          <thead>
            <tr>
              <th v-for="(key, i) in com298sKeys" :key="i">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="(item, indexProduto) in com298s" :key="indexProduto">
              <th v-for="(key, i) in com298sKeys" :key="i">
                {{ item[key] }}
              </th>
            </tr>
          </tbody>
        </v-simple-table>
        <v-simple-table>
          <thead>
            <tr>
              <th v-for="(key, i) in com298sProdutosKeys" :key="i">
                {{ key }}
              </th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr
              v-for="(produto, indexProduto) in com298sProdutos"
              :key="indexProduto"
            >
              <th v-for="(key, i) in com298sProdutosKeys" :key="i">
                {{ produto[key] }}
              </th>
            </tr>
          </tbody>
        </v-simple-table> -->
        <v-container fluid>
          <h2>Documento à pagar</h2>
          <CardCom342 :com342="com342"></CardCom342>
        </v-container>

        <v-container fluid>
          <h2>Ítens</h2>
          <v-card fluid class="pa-10">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="addItem">Novo +</v-btn>
            </v-row>
            <v-row v-for="(item, index) in com342Items" :key="index">
              <p>{{ index }}</p>
              <CardProduto
                :item="item"
                :index="index"
                :options-items="configsItems"
                @deleteMe="deleteItem"
              ></CardProduto>
            </v-row>

            <v-card-text> </v-card-text>
          </v-card>
        </v-container>
      </v-row>

      <v-row>
        <v-btn @click="cadastroCom342">Cadastro COM342</v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import strapiApi from "../../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import AutocompleteSelect from "../../../components/select/AutocompleteSelect.vue";
import conexosApi from "../../../../../libraries/conexos/conexosApi";
import conexosStrapiApi from "../../../../../libraries/conexos/conexosStrapiApi";
import CardProduto from "./CardProduto.vue";
import CardCom342 from "./CardCom342.vue";

export default {
  components: {
    AutocompleteSelect,
    CardProduto,
    CardCom342,
  },
  name: "NewPayment",
  data() {
    return {
      providers: [],
      provider: {},
      chipProvider: "",
      com298s: [],
      com298sKeys: [],
      com298sProdutos: [],
      com298sProdutosKeys: [],
      com342Items: [],
      com342: {
        priCod: 17009,
        filCod: 3,
      },
      federal_docs: [],
      chipDoc: "",
      configs: [],
      configsItems: [],
      configInfo: {},
      addresses: [],
      selAddress: {},
    };
  },

  watch: {
    chipProvider(val, oldVal) {
      this.getProviderInfo();
    },

    chipDoc(val, oldVal) {
      this.formatCom342();
    },
  },

  async mounted() {
    // alert("mounted");
    await this.getProviders();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    providersNames() {
      return this.providers?.map((el) => el.dpeNomPessoa.substring(0, 20));
    },
  },
  methods: {
    // test() {
    //   this.com342Items[2].saved = true;
    // },
    deleteItem(index) {
      console.log(JSON.stringify({ index }));
      this.com342Items.splice(index, 1);
    },

    async addItem() {
      let item = {
        description: "",
        configItem: {},
        value: 0,
        saved: false,
      };
      this.com342Items.push(item);
    },
    async getProviders() {
      let providers = await strapiApi.enterprises.getAllWithCom298();

      this.providers = providers;
      console.log(JSON.stringify({ providers }));
    },

    async getProviderInfo() {
      if (this.chipProvider) {
        //alert(this.chipProvider);
        this.provider = await strapiApi.enterprises.getByName(
          this.chipProvider
        );

        if (this.provider) {
          this.federal_docs = this.provider.federal_docs?.map((el) => el.doc);
        }

        // if (this.provider?.id) {
        //   this.com298s = await strapiApi.com298s.getFromPesCod(
        //     this.provider?.id
        //   );
        // }
      }
    },
    async formatCom342() {
      if (this.provider?.id) {
        this.addresses = this.provider?.addresses?.filter(
          (el) => el.pdcDocFederal == this.chipDoc
        );

        if (this.addresses?.length > 0) this.selAddress = this.addresses[0];

        if (!this.selAddress) {
          alert("Sem endereço");
          return;
        }
        // this.com298s = filterCom298s;
        // this.com298sProdutos = com298sProdutos;

        // //alert(this.provider?.dpeNomPessoa.split(" ")?.[0]);
        let configFilterList = {
          "gcdDesNome#LIKEP":
            this.provider?.dpeNomPessoa.split(" ")?.[0] ?? "HEUSI",
          priCod: this.com342?.priCod,
          docTip: 2,
          globalDocVldTipo: null,
          fPesCod: this.provider?.id,
          fEndCod: this.selAddress.endCod ?? 1,
        };

        console.log(JSON.stringify({ configFilterList }));

        let ansConfigs = await conexosStrapiApi.getConfDocProcesso(
          configFilterList
        );
        console.log("ansConfigs");
        console.log(JSON.stringify(ansConfigs));
        this.configs = ansConfigs?.rows;

        if (this.configs?.length > 0) {
          let config = this.configs[0];
          let ansConfigsItems = await conexosStrapiApi.getContasProj(
            config.gcdCod,
            parseInt(this.provider?.id),
            this.selAddress.endCod ?? 1
          );

          console.log("ansConfigsItems");
          console.log(JSON.stringify(ansConfigsItems));

          this.configsItems = ansConfigsItems?.rows;

          let filerConfig = {
            docTip: 2,
            globalDocVldTipo: null,
            priCod: this.com342?.priCod,
            pesCod: parseInt(this.provider?.id),
            pdcDocFederal: this.chipDoc,
            endCodFis: this.selAddress.endCod ?? 1,
            gcdCod: config.gcdCod,
          };
          let ansConfigInfo = await conexosStrapiApi.getCom342configInfo(
            filerConfig
          );

          this.configInfo = ansConfigInfo?.responseData;
        }
      }
    },

    // async formatCom342() {
    //   if (this.provider?.id) {
    //     let com298s = await strapiApi.com298s.getFromPesCod(
    //       this.provider?.id,
    //       this.chipDoc
    //     );

    //     let filterCom298s = com298s?.filter(this.onlyUniqueCom298);
    //     this.com342.com298 = com298s?.find((el) => el.endCod);

    //     //alert(this.com342.com298?.endCod);
    //     this.com342.com298 = com298s?.[0];
    //     if (!this.com342.com298) {
    //       alert("Sem com298");
    //       return;
    //     }

    //     if (com298s?.[0]) {
    //       this.com298sKeys = Object.keys(com298s?.[0]);
    //       this.com298sKeys = this.com298sKeys.filter(
    //         (el) =>
    //           el !== "createdAt" &&
    //           el !== "updatedAt" &&
    //           el !== "publishedAt" &&
    //           el !== "com_298_produtos"
    //       );
    //     }

    //     //console.log(JSON.stringify({ com298s }));
    //     let com298sProdutos = [];
    //     for (let i = 0; i < com298s?.length; i++) {
    //       let com298 = com298s[i];
    //       if (com298?.com_298_produtos?.length > 0) {
    //         //com298sProdutos = [...com298sProdutos, ...com298?.com_298_produtos];
    //         let produtosFilter = com298?.com_298_produtos.filter(
    //           (el) => el.ungDesNome == "IMPORTAÇÃO" && el.docVldTipo
    //         );
    //         //console.log(JSON.stringify({ produtosFilter }));
    //         if (produtosFilter?.length > 0)
    //           com298sProdutos = [...com298sProdutos, ...produtosFilter];
    //       }
    //     }

    //     com298sProdutos = com298sProdutos?.filter(this.onlyUniqueComProduto);

    //     if (com298sProdutos?.[0]) {
    //       this.com298sProdutosKeys = Object.keys(com298sProdutos?.[0]);
    //       this.com298sProdutosKeys = this.com298sProdutosKeys.filter(
    //         (el) =>
    //           el !== "createdAt" && el !== "updatedAt" && el !== "publishedAt"
    //       );
    //     }

    //     this.com298s = filterCom298s;
    //     this.com298sProdutos = com298sProdutos;

    //     //alert(this.provider?.dpeNomPessoa.split(" ")?.[0]);
    //     let configFilterList = {
    //       "gcdDesNome#LIKEP":
    //         this.provider?.dpeNomPessoa.split(" ")?.[0] ?? "HEUSI",
    //       priCod: this.com342?.priCod,
    //       docTip: 2,
    //       globalDocVldTipo: null,
    //       fPesCod: this.com342?.com298?.pesCod,
    //       fEndCod: this.com342?.com298?.endCod ?? 4,
    //     };

    //     console.log(JSON.stringify({ configFilterList }));

    //     let ansConfigs = await conexosStrapiApi.getConfDocProcesso(
    //       configFilterList
    //     );
    //     console.log("ansConfigs");
    //     console.log(JSON.stringify(ansConfigs));
    //     this.configs = ansConfigs?.rows;

    //     if (this.configs?.length > 0) {
    //       let config = this.configs[0];
    //       let ansConfigsItems = await conexosStrapiApi.getContasProj(
    //         config.gcdCod,
    //         this.com342?.com298?.pesCod,
    //         this.com342?.com298?.endCod ?? 1
    //       );

    //       console.log("ansConfigsItems");
    //       console.log(JSON.stringify(ansConfigsItems));

    //       this.configsItems = ansConfigsItems?.rows;

    //       let filerConfig = {
    //         docTip: 2,
    //         globalDocVldTipo: null,
    //         priCod: this.com342?.priCod,
    //         pesCod: this.com342?.com298?.pesCod,
    //         pdcDocFederal: this.com342?.com298?.pdcDocFederal,
    //         endCodFis: this.com342?.com298?.endCod ?? 1,
    //         gcdCod: config.gcdCod,
    //       };
    //       let ansConfigInfo = await conexosStrapiApi.getCom342configInfo(
    //         filerConfig
    //       );

    //       this.configInfo = ansConfigInfo?.responseData;
    //     }
    //   }
    // },
    onlyUniqueCom298(value, index, array) {
      //    if(value.)
      return (
        array.findIndex((el) => el.tpdDesNome == value.tpdDesNome) === index
      );
      //  return array.indexOf(value) === index;
    },

    onlyUniqueComProduto(value, index, array) {
      //    if(value.)
      return (
        array.findIndex((el) => el.ctpDesNome == value.ctpDesNome) === index
      );
      //  return array.indexOf(value) === index;
    },

    async cadastroCom342() {
      let config = this.configs[0];

      //Create items

      let items = [];

      for (let i = 0; i < this.configsItems.length; i++) {
        let configItem = this.configsItems[i];

        let com342Item = this.com342Items.find(
          (el) => el.configItem?.ctpCod == configItem.ctpCod
        );
        if (!com342Item) items.push(configItem);
      }

      for (let i = 0; i < this.com342Items.length; i++) {
        let com342Item = this.com342Items[i];

        let item = {
          ...com342Item?.configItem,
          total: null,
          tmpMnyValor: com342Item.value / 100,
        };
        items.push(item);
      }

      let total = this.com342Items.reduce((acc, curr) => acc + curr.value, 0);
      console.log(JSON.stringify({ items }));
      let com342 = {
        items,
        docTip: 2,
        pesCod: parseInt(this.provider?.id),
        globalDocVldTipo: null,
        frontModelName: "gerDocProcesso",
        priCod: this.com342?.priCod,
        // endCodFis: this.com342?.com298.endCod ?? 1,
        // pdcDocFederal: this.com342?.com298.pdcDocFederal,
        endCodFis: this.selAddress.endCod ?? 1,
        pdcDocFederal: this.chipDoc,

        valor: total / 100,
        docDtaEmissao: 1700611200000,
        docDtaMovimento: 1700611200000,

        tpcCod: this.configInfo?.tpcCod,
        gcdCod: config?.gcdCod,
        gcdVldTela: this.configInfo?.gcdVldTela,
        gcdVldPropria: this.configInfo?.gcdVldPropria,
        gcdVldFormaRateio: this.configInfo?.gcdVldFormaRateio,
        tpcDesNome: this.configInfo?.tpcDesNome,
        cfoEspCod: this.configInfo?.cfoEspCod,

        fisEspSerie: null,
        docEspNumero: this.com342.docEspNumero,
      };

      console.log(JSON.stringify({ com342 }));
      let ans = await conexosStrapiApi.com342gerDocProcesso(com342);

      alert(JSON.stringify(ans));
    },

    async test() {
      //alert("test");

      let ans = await conexosApi.cmn025list();

      alert(JSON.stringify({ ans }));
    },
  },
};
</script>

<style></style>
