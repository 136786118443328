import functions from "./functions";
var com298s = {
  async getFromPesCod(pesCod, pdcDocFederal) {
    const start = Date.now();
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/com298s?filters[pesCod]=${pesCod}&populate[0]=com_298_produtos,process`;
    if (pdcDocFederal) url += `&filters[pdcDocFederal]=${pdcDocFederal}`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    console.log(`Execution gettAll com298s time: ${(end - start) / 1000} s`);

    return functions.sanitize(res.data);
  },

  async post(com298) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/com298s`;
    let data = com298;
    data.id = com298.filCod * 10 ** 8 + com298.docCod;

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async put(com298) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/com298s/${com298.id}`;
    let data = com298;

    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async postProduto(com298Produto) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/com298-produtos`;
    let data = com298Produto;
    data.id = com298Produto.docCod * 100 + com298Produto.dprCodSeq;

    console.log("postProduto");
    console.log(JSON.stringify({ data }));

    let res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default com298s;
