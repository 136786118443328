import { gt } from "lodash";
import functions from "./functions";
var offers = {
  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    sellerId,
    invoiceNumber,
    sponsorName,
    kanastraCreatedAtFrom,
    kanastraCreatedAtTo
    // disabled,
  ) {
    const start = Date.now();
    const d = new Date("2000-01-01");
    var filters = {
      offer: {},
      kanastraAcquisition: {
        sentAt: { gt: d.toISOString() },
      },
    };

    if (sellerId) {
      filters.offer.seller = { id: { eq: sellerId } };
    }

    if (sponsorName) {
      filters.offer.sponsorName = {
        containsi: sponsorName,
      };
    }
    // alert(JSON.stringify(invoiceNumber));
    if (invoiceNumber) {
      filters.or = [
        {
          externalId: {
            containsi: invoiceNumber,
          },
        },
        {
          offer: {
            invoiceNumber: {
              containsi: invoiceNumber,
            },
          },
        },
      ];
    }

    filters.kanastraAcquisition.and = [];
    if (kanastraCreatedAtFrom) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let kanastraCreatedDateFrom = new Date(kanastraCreatedAtFrom);
      //kanastraCreatedDateFrom.setHours(kanastraCreatedDateFrom.getHours() + 24);
      filters.kanastraAcquisition.and.push({
        sentAt: {
          gte: kanastraCreatedDateFrom.toISOString(),
        },
      });
    }

    if (kanastraCreatedAtTo) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let kanastraCreatedDateTo = new Date(kanastraCreatedAtTo);
      kanastraCreatedDateTo.setHours(kanastraCreatedDateTo.getHours() + 24);
      filters.kanastraAcquisition.and.push({
        sentAt: {
          lte: kanastraCreatedDateTo.toISOString(),
        },
      });
    }

    console.log(JSON.stringify({ filters }));

    var query = `query offers($filters:KanastraOfferFiltersInput!){
      kanastraOffers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            externalId
            createdAt
            kanastraUpdatedAt
            status
            kanastraAcquisition{
              data{
                id
                attributes{
                  sentAt
                }
              }
            }
            offer{ 
              data{
                 id
                  attributes{
                    offerExternalId
                    sponsorName
                    sponsorPersonType
                    sponsorGovernmentId
                    sponsorAddress
                    sponsorAddressNumber
                    sponsorNeighborhood
                    sponsorAddressComplement
                    sponsorCity
                    sponsorState
                    sponsorCountry
                    sponsorZipCode
                    sponsorBank
                    sponsorAgency
                    sponsorAgencyDigit
                    sponsorAccount
                    sponsorAccountDigit
                    assetType
                    invoiceNumber
                    invoiceDate
                    invoiceKey
                    totalInstallments
                    paymentValue
                    paymentDate
                    kanastraStatus
                    kanastraCreatedAt
                    kanastraUpdatedAt
                    kanastraAcquisitionId
                    kanastraUuid
                    kanastraStatus
                    kanastraCreatedAt
                    kanastraUpdatedAt
                    kanastraAcquisitionId
                    kanastraUuid
                    updateNumber
                    disabled
                    fileJson
                    fileContent
                    fileContentType
                    kanastraRequestCount
                    seller{
                      data{
                        id
                        attributes{
                          name
                          city
                          state
                        }
                      }
                    }
                    installments{
                      data{
                        attributes{
                          installmentAmount
                          installmentDueDate
                        }
                      }
                    }
                  } 
              }
            }
          }  
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination offers time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.kanastraOffers?.meta?.pagination;
    const values = functions.sanitize(res.data.kanastraOffers.data);
    return { values, pagination };
  },
};

export default offers;
