var conexosStrapiApi = {
  async arq020List(filCod, priCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      priCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arq020List`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesList(filCod, dcmCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesList`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesDownload(filCod, dcmCod, adfCodSeq) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
      adfCodSeq,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesDownload`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async arqDocFilesRequest(filCod, dcmCod, adfCodSeq) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      dcmCod,
      adfCodSeq,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arqDocFilesRequest`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async log009ParcelasList(invCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/log009ParcelasList`;

    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;

    var raw = JSON.stringify({
      invCod: parseInt(invCod),
      filCod: parseInt(filCod),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async imp216List(priCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    console.log(JSON.stringify({ url }));
    // var myHeaders = {};
    // myHeaders["Content-Type"] = "application/json";
    // myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";
    // myHeaders["fil_cod"] = parseInt(filCod);
    // myHeaders["filcod"] = parseInt(filCod);

    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";

    let raw = {
      filCod: parseInt(filCod),
      url: "/api/imp216/list",
      fieldList: [],
      filterList: {
        filCod: parseInt(filCod),
        priCod: parseInt(priCod),
        etpVldVisivel: 1,
      },
      pageNumber: 1,
      pageSize: 20,
    };

    // var raw = JSON.stringify({
    //   filCod: 3,
    //   url: "/api/imp216/list",
    //   fieldList: [],
    //   filterList: {
    //     filCod: 3,
    //     priCod: 16287,
    //     etpVldVisivel: 1,
    //   },
    //   pageNumber: 1,
    //   pageSize: 20,
    // });

    console.log("raw");
    console.log(JSON.stringify(raw));
    console.log(JSON.stringify(raw));
    console.log("ans");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    console.log(JSON.stringify({ ans }));
    return ans;
  },

  async com298List(filCod, filterList) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/com298/list`;
    console.log(JSON.stringify({ url }));
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";

    let raw = {
      filCod: parseInt(filCod),
      url: "/api/com298/list",
      fieldList: [
        "docCod",
        "priCod",
        "priEspRefcliente",
        "docDtaEmissao",
        "docEspNumero",
        "docVldTipoAdto",
        "tpdDesNome",
        "pesCod",
        "dpeNomPessoa",
        "ufEspSigla",
        "mnyBruto",
        "docMnyValor",
        "vldStatus",
        "docVldPrevisao",
        "gcdDesNome",
        "pdcDocFederal",
        "docDtaMovimento",
        "filCod",
        "docTip",
      ],

      filterList,
      pageNumber: 1,
      pageSize: 20,
      orderList: {
        orderList: [
          {
            propertyName: "docDtaEmissao",
            order: "desc",
          },
        ],
      },
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async com298post(com298) {
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: "/api/com298",
      filCod: `${com298.filCod}`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,
      ...com298,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;

    try {
      let res = await fetch(url, requestOptions);

      let json = await res.json();

      if (!res.ok) {
        throw new Error(
          JSON.stringify({
            statusCod: res.status,
            statusText: res.statusText,
            payload: json,
            message: "Error posting com298",
          })
        );
      }
      return json;
    } catch (error) {
      console.error("Fetch Error:", error.message);
      // You can return an error object or message here
      return { error: true, message: JSON.parse(error.message) };
    }
  },

  async com298comDocProduto(com298produto) {
    //let path = "/api/com298";
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: "/api/com298/comDocProdutos",
      filCod: `${com298produto.filCod}`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,
      ...com298produto,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(JSON.stringify(res));

        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async getCom298ContasProjeto(page, filCod, priCod, search) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/com298/list`;
    console.log(JSON.stringify({ url }));
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";

    let raw = {
      filCod: parseInt(filCod),
      url: "/api/lov/ContasProjetoCtb",

      fieldList: ["ctpDesNome", "ctpEspConta", "ctpCod", "prjCod"],
      filterList: {
        prjCod: 2,
        docTip: 2,
        priCod,
        tpdCod: 178,
        priCodProd: priCod,

        // prjCod: 2,
        // docTip: 2,
        // priCod: 17009,
        // tpdCod: 178,
        // priCodProd: 17009,
      },
      pageNumber: page,
      orderBy: "asc",
      sortBy: "ctpDesNome",
    };

    if (search) {
      raw.filterList["ctpDesNome#LIKEP"] = search?.toUpperCase();
    }

    console.log(JSON.stringify({ raw }));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async getCom298CentroDeCustos(page, filCod, search) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    //var url = `${process.env.VUE_APP_STRAPI_URL}/api/com298/list`;
    console.log(JSON.stringify({ url }));
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";

    let raw = {
      filCod: parseInt(filCod),
      url: "/api/lov/CentroCustosAtv",

      fieldList: ["ccuEspConta", "ccuDesNome"],
      filterList: {
        ccuVldLancamentos: 1,
      },
      pageNumber: 1,
      orderBy: "asc",
      sortBy: "ccuDesNome",
    };

    if (search) {
      raw.filterList["ccuDesNome#LIKEP"] = search?.toUpperCase();
    }

    console.log(JSON.stringify({ raw }));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async getConfDocProcesso(filterList) {
    //let path = "/api/com298";
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: "/api/lov/ConfigDocProcesso",
      filCod: `3`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,

      fieldList: ["gcdDesNome", "gcdVldTela", "gcdCod"],
      filterList,
      pageNumber: 1,
      orderBy: "asc",
      sortBy: "gcdDesNome",
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(JSON.stringify(res));

        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async getContasProj(gcdCod, pesCod, endCod) {
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: `/api/com342/gerDoc/contasProj/list/${gcdCod}/${pesCod}/${endCod}`,
      filCod: `3`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,

      fieldList: ["prjCod", "ctpCod", "ctpDesNome", "total", "tmpMnyValor"],
      filterList: {},
      pageNumber: 1,
      pageSize: -1,
      serviceName: "com068.geracaoDocumentosContasProjeto",
      orderList: {
        orderList: [
          {
            propertyName: "prjCod",
            order: "asc",
          },
        ],
      },
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(JSON.stringify(res));

        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async getCom342configInfo(config) {
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: "/api/com342/gerDoc/validaConfigDoc",
      filCod: `3`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,
      ...config,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      //body: JSON.stringify({ url: "/api/com298", method: "POST", ...com298 }),
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(JSON.stringify(res));

        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async com342gerDocProcesso(com342) {
    var myHeaders = {
      Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
      "Content-Type": "application/json;charset=UTF-8",
    };

    let raw = {
      method: "POST",
      url: "/api/com342/gerDocProcesso",
      filCod: `3`,
      cookie: `sid=${window.conexosAuth}`,
      usnCod: `${window.usnCod}`,
      ...com342,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      //body: JSON.stringify({ url: "/api/com298", method: "POST", ...com298 }),
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/api`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(JSON.stringify(res));

        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async com308put(com308) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;
    // myHeaders["url"] = "/api/imp216/list";
    //https://saintemarie-test.conexos.cloud/api/com308/modalidadePag
    let raw = {
      method: "PUT",
      url: "/api/com308/modalidadePag",
      ...com308,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async validaCodigoBarras(data) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;

    let raw = {
      filCod: 1,
      url: "/api/com308/validacao/codigoBarras",
      ...data,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(
      `${VUE_APP_STRAPI_URL}/api/conexos/proxy`,
      requestOptions
    )
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async getDespesasProcesso(priCod, filCod) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;

    let raw = {
      filCod,
      url: `/api/imp021/DespesasProcesso/${priCod}`,

      fieldList: [
        "prjCod",
        "ctpDesNome",
        "pidMnyValormn",
        "pidVldLibera",
        "pdiVldOrigemDesp",
        "pidVldFormaReteio",
        "impDesNome",
        "moeEspNome",
        "pidVldTipo",
        "pidVldNfserv",
        "prdCod",
        "prdDesNome",
        "pidVldFonte",
        "pidDtaTaxas",
        "pidFltTxMneg",
        "pidMnyValorMneg",
        "pidMnyFatEntPri",
        "pidMnyFatEntFil",
        "pidMnyFatSaiPri",
        "pidMnyFatSaiFil",
        "pidVldStatus",
        "tdsDesTipo",
        "pedCod",
        "pddPreValorUn",
        "pddPreValorUnFaturado",
        "filCod",
        "priCod",
        "ctpCod",
        "pidCodSeq",
        "usnDesNomeCad",
        "pidTimCadastro",
        "usnDesNomeInat",
        "pidTimInativacao",
        "vldRegNota",
        "idtCod",
        "impCod",
        "moeCod",
        "vldMarkup",
        "priVldTipo",
      ],
      filterList: {
        "pidVldStatus#EQ": "1",
      },
      pageNumber: 1,
      pageSize: 1000,
      serviceName: "imp021.ImpProcessoDespesas",
      orderList: {
        orderList: [
          {
            propertyName: "prjCod",
            order: "asc",
          },
        ],
      },
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },

  async confirmaCodigoBarras(data) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/conexos/proxy`;
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] = `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`;

    let raw = {
      filCod: 1,
      url: "/api/com308/validacao/confirmaCodigoBarras",
      ...data,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        return json;
      });

    //console.log(JSON.stringify({ ans }));
    return ans;
  },
};

export default conexosStrapiApi;
