var conexosApi = {
  async imp216List(filCod, priCod) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    myHeaders["Authorization"] =
      "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = JSON.stringify({
      filCod,
      priCod,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let ans = await fetch(
      `${process.env.VUE_APP_STRAPI_URL}/api/conexos/arq020List`,
      requestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        return json;
      });

    return ans;
  },

  async login(username, password, sessionToKill) {
    var myHeaders = {};
    myHeaders["Content-Type"] = "application/json";
    // myHeaders["Authorization"] =
    //   "Bearer 01ec26bb5ea553cfddc8c13103e7e6b3ca1ee263ea32c0f67733662929f913e4d8ea92ee7eea63a11fdf5daf7516a83819db9043e6132b08da57266c7698f8774e235b2273947ede3ff2c20780cce2db7b3f1a3eb54e50f79a8fa20f80a8f70ce24726e71679250a95e42662365275fe2377a2cb30dd5258f406b93d540c213a";

    var raw = {
      username,
      password,
      // sessionToKill: "8e434704",
    };

    if (sessionToKill) raw.sessionToKill = sessionToKill;

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    console.log(`url:${process.env.VUE_APP_CONEXOS_URL}/api/login`);
    let status;
    let auth;

    let ans = await fetch(
      `${process.env.VUE_APP_CONEXOS_URL}/api/login`,
      requestOptions
    )
      .then((res) => {
        status = res.status;
        console.log(JSON.stringify({ status }));
        console.log("xxxxxxx");
        if (status == 200) {
          window.conexosAuth = res?.headers.get("x-cnx-auth");
        }
        console.log("xxxxxxx");
        console.log(JSON.stringify(res));
        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },
  async com298post(com298) {
    let path = "/api/com298";
    var myHeaders = {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json, text/plain, */*",
      "Accept-Language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
      "Cnx-dataLanguage": "pt",
      "Cnx-filCod": `${com298.filCod}`,
      "Cnx-usnCod": `${window.usnCod}`,
      Connection: "keep-alive",
      Cookie: `sid=${window.conexosAuth}`,
    };

    console.log(JSON.stringify({ myHeaders }));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(com298),
      redirect: "follow",
    };

    let url = `${process.env.VUE_APP_CONEXOS_URL}${path}`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(url, requestOptions)
      .then((res) => {
        console.log("RES");
        console.log(JSON.stringify(res));
        // status = res.status;
        // console.log(JSON.stringify({ status }));
        // console.log("xxxxxxx");
        // if (status == 200) {
        //   window.conexosAuth = res?.headers.get("x-cnx-auth");
        //   alert(window.conexosAuth);
        // }
        // console.log("xxxxxxx");
        // console.log(JSON.stringify(res));
        return res.json();
      })
      .then((json) => {
        console.log("JSON");
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },

  async cmn025list() {
    var myHeaders = {
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json, text/plain, */*",
      "Accept-Language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
      "Cnx-dataLanguage": "pt",
      "Cnx-filCod": `3`,
      "Cnx-usnCod": `${window.usnCod}`,
      Connection: "keep-alive",
      Cookie: `sid=${window.conexosAuth}`,
      //Cookie: "sid=efebaa0b-ee66-4bc3-861a-28884609aa07",
    };

    let raw = {
      pageNumber: 1,
      pageSize: 100,
      filterList: {
        "vldValido#EQ": 1,
      },
    };

    console.log(JSON.stringify({ myHeaders }));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    let url = `${process.env.VUE_APP_CONEXOS_URL}/api/cmn025/list`;
    console.log(`url:${url}`);
    let status;
    let ans = await fetch(
      "https://saintemarie-test.conexos.cloud/api/cmn025/list",
      requestOptions
    )
      .then((res) => {
        // status = res.status;
        // console.log(JSON.stringify({ status }));
        // console.log("xxxxxxx");
        // if (status == 200) {
        //   window.conexosAuth = res?.headers.get("x-cnx-auth");
        //   alert(window.conexosAuth);
        // }
        // console.log("xxxxxxx");
        // console.log(JSON.stringify(res));
        return res.json();
      })
      .then((json) => {
        console.log(JSON.stringify(json));
        return json;
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Fetch Error:", error);
      });

    return ans;
  },
};

export default conexosApi;
