import functions from "./functions";
var sellers = {
  async getWithPagination(page, pageSize, sort, sortDesc) {
    const start = Date.now();
    let filters = {};
    var query = `query sellers($filters:SellerFiltersInput!){
      sellers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            name
            personType
            governmentId
            address
            addressNumber
            addressComplement
            neighborhood
            city
            state
            country
            zipCode
            bank
            agency
            agencyDigit
            account
            accountDigit
            coobrigation
            prefix
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination sellers time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.sellers?.meta?.pagination;
    const values = functions.sanitize(res.data.sellers.data);
    return { values, pagination };
  },
  async getById(nfseId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/sellers?filters[id]=${nfseId}&populate=nfse_servicos,process`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res.data);

    if (values && values.length > 0) return values[0];
    else return null;
  },

  async getByFilters(page, pageSize, sort, sortDesc, filters) {
    const start = Date.now();
    var query = `query sellers($filters:NfseFiltersInput!){
      sellers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination sellers time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.sellers?.meta?.pagination;
    const values = functions.sanitize(res.data.sellers.data);
    return { values, pagination };
  },
};

export default sellers;
