<template>
  <div>
    <v-dialog
      v-model="showDialog"
      width="1800px"
      class="pa-0 ma-0"
      style="background-color: white"
    >
      <v-card class="">
        <v-toolbar class="primary" dark
          >Conta do projeto e centro de custos
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
        >

        <v-card-text>
          <p>Conta de projeto</p>
          <v-row>
            <v-col cols="1">
              <v-text-field
                label="Projeto"
                disabled
                v-model="selContaProjeto.ctpEspConta"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Conta de projeto"
                disabled
                v-model="selContaProjeto.ctpDesNome"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-btn
                v-if="!showSearchContaProjeto"
                class="primary"
                @click="showSearchContaProjeto = true"
                ><v-icon>mdi-magnify </v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <p>
          {{ JSON.stringify(selContaProjeto) }}
        </p> -->

        <SearchContaProjeto
          v-model="showSearchContaProjeto"
          :process="process"
          @selectContaProjeto="selectContaProjeto"
        ></SearchContaProjeto>

        <v-card-text>
          <p>Centro de custos</p>
          <v-row>
            <v-col cols="1">
              <v-text-field
                label="Projeto"
                disabled
                v-model="selCentroDeCustos.ccuEspConta"
              ></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="Conta de projeto"
                disabled
                v-model="selCentroDeCustos.ccuDesNome"
              ></v-text-field>
            </v-col>
            <!-- <p>{{ JSON.stringify(selCentroDeCustos) }}</p> -->
            <v-col cols="3">
              <v-btn
                v-if="!showSearchCentroDeCustos"
                class="primary"
                @click="showSearchCentroDeCustos = true"
                ><v-icon>mdi-magnify </v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <p>
          {{ JSON.stringify(selContaProjeto) }}
        </p> -->

        <SearchCentroDeCustos
          v-model="showSearchCentroDeCustos"
          :process="process"
          @selectCentroDeCustos="selectCentroDeCustos"
        ></SearchCentroDeCustos>

        <v-card-text>
          <v-row>
            <v-btn class="primary">Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="handleOk">OK</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import strapiApi from "../../../../../libraries/strapi/strapiApi";
import SearchContaProjeto from "./SearchContaProjeto";
import SearchCentroDeCustos from "./SearchCentroDeCustos";

export default {
  name: "search-nfse-dialog",
  props: ["value", "showError", "processRef", "process"],
  components: {
    SearchContaProjeto,
    SearchCentroDeCustos,
  },
  data() {
    return {
      showSearchContaProjeto: true,
      showSearchCentroDeCustos: false,
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 15,
      processes: [],
      filCod: 3,
      selContaProjeto: {},
      selCentroDeCustos: {},
      headers: [
        {
          text: "Código",
          value: "PRI_COD",
          width: "20px",
          sortable: true,
        },
        {
          text: "Filial",
          value: "FIL_COD",
          align: "start",
          sortable: false,
        },
        {
          text: "Data de abertura",
          value: "PRI_DTA_ABERTURA",
          align: "start",
          sortable: false,
        },
        //{ text: "Pdf link", value: "pdfLink", sortable: false },

        {
          text: "Nome pessoa",
          value: "DPE_NOM_PESSOA_LIG",
          sortable: false,
        },

        {
          text: "Selecionar",
          value: "action",
          sortable: false,
        },

        // {
        //   text: "Valor Serviços",
        //   value: "ServicoValorServicos",
        //   sortable: false,
        // },
        //{ text: "Número", value: "Numero", sortable: false },
        // { text: "Ação", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showDialog() {
      //alert("changed");
      this.search = this.processRef;
    },
  },
  async mounted() {
    //alert(this.processRef);
    // this.search = this.processRef;
    // await this.doSearch();
    this.search = this.processRef;

    // await this.doSearch();
  },
  methods: {
    handleOk() {
      if (this.selCentroDeCustos?.ccuCod && this.selContaProjeto?.ctpCod) {
        let data = {
          contaProjeto: this.selContaProjeto,
          centroDeCustos: this.selCentroDeCustos,
        };
        this.$emit("selectConfigProduto", data);
      } else {
        alert("Preencher todos campos");
      }
      // console.log(
      //   JSON.stringify({
      //     contaProjeto: this.selContaProjeto,
      //     centroDeCustos: this.selCentroDeCustos,
      //   })
      // );
      // alert(
      //   JSON.stringify({
      //     contaProjeto: this.selContaProjeto,
      //     centroDeCustos: this.selCentroDeCustos,
      //   })
      // );
    },

    selectContaProjeto(projeto) {
      this.selContaProjeto = projeto;
      this.showSearchContaProjeto = false;
    },

    selectCentroDeCustos(item) {
      this.selCentroDeCustos = item;
      this.showSearchCentroDeCustos = false;
    },

    closeError() {
      this.$emit("closeError");
    },
    closeModal() {
      this.showDialog = false;
    },

    yes() {
      if (this.containers[0] && this.containers[0].containerNumber)
        this.$emit("yes", {
          containerNumber:
            this.containers[0] && this.containers[0].containerNumber,
          blContainersRef: this.blContainersRef ? this.blContainersRef : "",
        });
      else alert("Informar container e BL");
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("do search");
      const { values, pagination } =
        await strapiApi.processes.getByRefWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          this.search,
          this.filCod
        );

      console.log(JSON.stringify({ values, pagination }));
      this.processes = values;
      //alert(JSON.stringify({  pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>
