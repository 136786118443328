import functions from "./functions";
var processes = {
  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    dateStart,
    dateEnd,
    orderers,
    exporters,
    filterShipped
  ) {
    const start = Date.now();

    var filters = {
      and: [
        {
          PRI_DTA_ABERTURA: { gte: dateStart.toISOString() },
        },
        {
          PRI_DTA_ABERTURA: { lte: dateEnd.toISOString() },
        },
      ],
    };

    if (orderers && orderers.length > 0) {
      if (orderers.length == 1) {
        filters.and.push({
          DPE_NOM_PESSOA_LIG: { containsi: orderers[0] },
        });
      } else {
        filters.and.push({
          or: orderers.map((el) => ({
            DPE_NOM_PESSOA_LIG: { containsi: el },
          })),
        });
      }
    }

    if (exporters && exporters.length > 0) {
      if (exporters.length == 1) {
        filters.and.push({
          NOME_EXPORTADOR: { containsi: exporters[0] },
        });
      } else {
        filters.and.push({
          or: exporters.map((el) => ({
            NOME_EXPORTADOR: { containsi: el },
          })),
        });
      }
    }

    // if (filterShipped > 0) {
    //   if (filterShipped == 1) {
    //     filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
    //     filters.and.push({ DI: { eq: null } });
    //   }
    //   if (filterShipped == 2)
    //     filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
    //   if (filterShipped == 3) filters.and.push({ DI: { ne: null } });
    // }

    if (filterShipped > 0) {
      //Em transito
      if (filterShipped == 1) {
        filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
        filters.and.push({ DTA_CHEGADA_BL: { eq: null } });
      }
      //A embarcar
      if (filterShipped == 2)
        filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
      //Chegou
      if (filterShipped == 3)
        filters.and.push({ DTA_CHEGADA_BL: { ne: null } });
    }

    var query = `query processes($filters:ProcessFiltersInput!){
      processes( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          attributes{
            VLR_TOT_INV
            CANAL
            NOME_EXPORTADOR
            PRI_DTA_ABERTURA
            DPE_NOM_PESSOA_LIG
            PRI_ESP_REFCLIENTE
            DI
            REGISTRO_DI
            PRI_COD
            FIL_COD
            DTA_EMBARQUE_BL
            FRETE_DOLAR_BL
            VALOR_FOB
            ARMAZEM_BL
            PAIS_ORIGEM
            DTA_CHEGADA_BL
            PRI_VLD_STATUS
            PRI_VLD_TIPO
            DTA_FECH_OPERACIONAL
            invoices{
              data{
                attributes{
                  INC_ESP_SIGLA
                  INV_ESP_PO
                  items{
                    data{
                      attributes{
                        PRD_DES_NOME
                        VLR_FINAL
                      }
                    }
                  }
                }
              }
            }
            transports{
              data{
                id
                 attributes{
                  ARMADOR
                  CNT_DTA_PREV_EMB
                  CNT_DTA_EMBARQUE
                  PREVISAO_CHEGADA
                  CNT_DTA_CHEGADA
                  containers{
                    data{
                      id
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination processes time: ${(end - start) / 1000} s`
      );

    const pagination = res?.data?.processes?.meta?.pagination;
    const values = functions.sanitize(res.data.processes.data);
    return { values, pagination };
  },

  async getByRefWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    filCod
  ) {
    const start = Date.now();

    var filters = {
      and: [
        {
          FIL_COD: { eq: filCod },
        },
      ],
    };

    if (filterName) {
      filters.and.push({
        PRI_ESP_REFCLIENTE: { contains: filterName },
      });
    }

    var query = `query processes($filters:ProcessFiltersInput!){
      processes( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
      
            PRI_COD
            FIL_COD
            DPE_NOM_PESSOA
            DPE_NOM_PESSOA_LIG
            PRI_DTA_ABERTURA
      
            DTA_EMBARQUE_BL
            FRETE_DOLAR_BL
            VALOR_FOB
            ARMAZEM_BL
            PAIS_ORIGEM
            DTA_CHEGADA_BL
            PRI_VLD_STATUS
            PRI_VLD_TIPO
            DTA_FECH_OPERACIONAL
            VLR_TOT_INV
            CANAL
            NOME_EXPORTADOR
            PRI_DTA_ABERTURA

            PRI_ESP_REFCLIENTE
            DI
            REGISTRO_DI
       
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(graphql);
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination processes time: ${(end - start) / 1000} s`
      );

    const pagination = res?.data?.processes?.meta?.pagination;
    const values = functions.sanitize(res.data.processes.data);
    return { values, pagination };
  },

  async getByCod(priCod, filCod) {
    const start = Date.now();

    var filters = {
      and: [
        {
          PRI_COD: { eq: priCod },
        },
        {
          FIL_COD: { eq: filCod },
        },
      ],
    };

    var query = `query processes($filters:ProcessFiltersInput!){
      processes( sort:"PRI_DTA_ABERTURA" filters:$filters ,pagination:{pageSize:1}){
        data{
          id
          attributes{
            PRI_COD
            FIL_COD
            VLR_TOT_INV
            CANAL
            NOME_EXPORTADOR
            PRI_DTA_ABERTURA
            DPE_NOM_PESSOA
            DPE_NOM_PESSOA_LIG
            PRI_ESP_REFCLIENTE
            DI
            REGISTRO_DI
            DTA_EMBARQUE_BL
            FRETE_DOLAR_BL
            DTA_CHEGADA_BL
            DTA_EMISS_1ENTRADA
            DTA_EMISS_1SAIDA
            VALOR_FOB
            ARMAZEM_BL
            PAIS_ORIGEM
            PES_COD_LIG
            DTA_PREV_EMBARQUE_BL
            DTA_PREV_CHEGADA_BL
            PRI_DTA_FECHAMENTO
            DTA_FECH_OPERACIONAL
            dis{
              data{
                attributes{
                    DEPACHO_DTADESEMBARACO
                }
              }
            }
            invoices{
              data{
                attributes{
                  INC_ESP_SIGLA
                  INV_ESP_PO
                  INV_COD
                  MOE_ESP_NOME
                  MOE_ESP_SIGLA
                  MOEDA_INV
                  items{
                    data{
                      attributes{
                        INV_COD
                        INI_ITEM
                        PRD_COD
                        PRD_DES_NOME
                        VLR_FINAL
                        PRU_QTD_EMBALAGEM
                        PRD_LNG_COMPLEMENTO
                        TEC_ESP_COD
                        TEC_ESP_COD_SEQ
                        UND_DES_NOME
                        INI_QTD_QUANTIDADE
                        INI_PRE_PESO_BRUTO
                        INI_PRE_PESO_LIQUIDO
                        INI_PRE_VALORTOTAL
                        INI_PCT_II
                        INI_PCT_IPI
                        INI_PCT_PIS
                        INI_PCT_COFINS
                        INI_ESP_COD_EXTERNO
                      }
                    }
                  }
                }
              }
            }
            transports{
              data{
                id
                 attributes{
                  ARMADOR
                  CNT_COD
                  CNT_ESP_NUMERO_HOUSE
                  CNT_ESP_NUMERO_MASTER
                  CNT_VLD_TP_DOC_CARGA_DESC
                  CNT_DTA_EMISSAO
                  CE_MERCANTE
                  VIA_DES_NOME
                  EXPORTADOR
                  NOME_NAVIO
                  CNT_DTA_PREV_EMB
                  CNT_DTA_EMBARQUE
                  PREVISAO_CHEGADA
                  CNT_DTA_CHEGADA
                  containers{
                    data{
                      id
                      attributes{
                        CNT_ESP_NUMERO
                        CTO_ESP_NRLACRE
                        CTO_DTA_VENCTO_FREETIME
                        PRE_DEMURGE
                        DTA_CHEGADA
                        CTO_DES_NOME
                        
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    const processes = functions.sanitize(res.data.processes.data);
    if (processes.length > 0) return processes[0];
    else return {};
  },

  async getExporters(dateStart, dateEnd, orderers) {
    if (window.debug) console.log(JSON.stringify({ orderers }));

    var filters = `{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }}, {NOME_EXPORTADOR:{ne:null}} `;

    //var filters = `{ and:[{NOME_EXPORTADOR:{ne:null}} `;
    if (orderers && orderers.length > 0) {
      //Insert filter enconmendante
      if (orderers.length > 1) {
        filters += `, {or:[   `;
        for (var a = 0; a < orderers.length; a++) {
          const encomendante = orderers[a];
          if (a == 0)
            filters += `{DPE_NOM_PESSOA_LIG:{containsi:"${encomendante}" }}`;
          else
            filters += `,{DPE_NOM_PESSOA_LIG:{containsi:"${encomendante}" }}`;
        }
        filters += `]}`;
      } else {
        filters += `,{DPE_NOM_PESSOA_LIG:{containsi:"${orderers[0]}" }}`;
      }
    }

    filters += `]}`;

    if (window.debug) console.log(JSON.stringify({ filters }));
    var query = `query processes{
      processes( filters:${filters} ,pagination:{pageSize:10000}){
        data{
          attributes{
            NOME_EXPORTADOR
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {},
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });
    const processes = functions.sanitize(
      res && res.data && res.data.processes && res.data.processes.data
    );

    return (
      processes &&
      processes
        .filter(
          (arr, index, self) =>
            index ===
            self.findIndex((t) => t.NOME_EXPORTADOR === arr.NOME_EXPORTADOR)
        )
        .map((el) => el.NOME_EXPORTADOR)
    );
  },

  async getCountries() {
    var filters = `{PAIS_ORIGEM:{ne:null}} `;
    var query = `query processes{
      processes( filters:${filters} ,pagination:{pageSize:10000}){
        data{
          attributes{
            PAIS_ORIGEM
          }
        }
      }
    }
    `;
    if (window.debug) console.log(JSON.stringify({ query }));
    var graphql = JSON.stringify({
      query,
      variables: {},
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });
    const processes = functions.sanitize(
      res && res.data && res.data.processes && res.data.processes.data
    );

    return (
      processes &&
      processes
        .filter(
          (arr, index, self) =>
            index === self.findIndex((t) => t.PAIS_ORIGEM === arr.PAIS_ORIGEM)
        )
        .map((el) => el.PAIS_ORIGEM)
    );
  },

  async getSummary(dateStart, dateEnd, enterprises, exporters, filterShipped) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/process/summary`;
    if (window.debug) console.log("getSummary");
    var body = {
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString(),
      enterprises,
      exporters,
      filterShipped,
    };
    if (window.debug) console.log(JSON.stringify({ body }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getWarehouseAlerts() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/process/warehouseAlerts`;
    if (window.debug) console.log("getSummary");
    var body = {};
    if (window.debug) console.log(JSON.stringify({ body }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getKpis(dateStart, dateEnd, enterprises, exporters, filterShipped) {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/process/kpis`;
    if (window.debug) console.log("getKpis");
    var body = {
      dateStart: dateStart.toISOString(),
      dateEnd: dateEnd.toISOString(),
      enterprises,
      exporters,
      filterShipped,
    };
    if (window.debug) console.log(JSON.stringify({ body }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default processes;
