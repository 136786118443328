<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="label"
        v-model="dateValue"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        color="actionButton"
        event-color="actionButton"
        style="
          color: black;
          font-size: 16px !important;
          font-weight: 600 !important;
        "
      ></v-text-field>
    </template>
    <v-card class="ma-0 pa-0">
      <v-date-picker
        v-model="dateValue"
        type="date"
        no-title
        @input="menu = false"
        reactive
        scrollable
        color="actionButton"
      ></v-date-picker>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "DateInput",
  props: ["value", "label"],
  components: {},

  computed: {
    dateValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style></style>
