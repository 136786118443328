import Vue from "vue";
import Vuex from "vuex";
//import VuexPersistence from "vuex-persist";
import createPersistedState from "vuex-persistedstate";

import auth from "./store/auth";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barColor: "white",
    barImage:
      "https://firebasestorage.googleapis.com/v0/b/hebelsolar.appspot.com/o/sidebar-5.jpg?alt=media&token=bf30b545-edfe-491a-a039-c5953de0bdbe",
    drawer: null,
    invoices: [],
    processes: [],
    invoices_items: [],
    transports: [],
    containers: [],
    countriesAcc: [],
    chipsEncomendantes: [],
    chipsExportadores: [],
    dateFilterStart: "2023-01-01",
    dateFilterEnd: new Date().toISOString().substring(0, 10),
    filterShipped: 0,
    summaryData: {},
    kpis: {},
    selectedInstallments: [],
  },
  mutations: {
    SET_SELECTED_INSTALLMENTS(state, payload) {
      state.selectedInstallments = payload;
    },
    SET_KPIS(state, payload) {
      state.kpis = payload;
    },
    SET_SUMMARY_DATA(state, payload) {
      state.summaryData = payload;
    },
    SET_FILTER_SHIPPED(state, payload) {
      state.filterShipped = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_INVOICES(state, payload) {
      state.invoices = payload;
    },
    SET_PROCESSES(state, payload) {
      state.processes = payload;
    },
    SET_INVOICES_ITEMS(state, payload) {
      state.invoices_items = payload;
    },
    SET_TRANSPORTS(state, payload) {
      state.transports = payload;
    },
    SET_CONTAINERS(state, payload) {
      state.containers = payload;
    },

    SET_COUNTRIES_ACC(state, payload) {
      state.countriesAcc = payload;
    },
    SET_CHIPS_ENCOMENDANTES(state, payload) {
      state.chipsEncomendantes = payload;
    },

    SET_CHIPS_EXPORTADORES(state, payload) {
      state.chipsExportadores = payload;
    },

    SET_DATE_FILTER_START(state, payload) {
      state.dateFilterStart = payload;
    },

    SET_DATE_FILTER_END(state, payload) {
      state.dateFilterEnd = payload;
    },
  },
  actions: {},
  modules: {
    auth,
  },
  //plugins: [new VuexPersistence().plugin],
  plugins: [createPersistedState()],
});
