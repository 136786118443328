<template>
  <div>
    <v-card class="pa-4" v-if="value" style="min-height: 60vh">
      <v-toolbar style="background-color: lightblue"
        >Busca de conta projeto
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal"> x </v-btn></v-toolbar
      >
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              label="Descrição"
              placeholder=""
              v-model="search"
            ></v-text-field>
          </v-col>

          <v-col cols="2">
            <v-btn class="primary" @click="doSearch">Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="contasProjeto"
          item-key="name"
          class="custom-table"
          :page.sync="pagination.page"
          :items-per-page.sync="pagination.itemsPerPage"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.sortDesc"
          :server-items-length.sync="pagination.serverItemsLength"
          :footer-props="{
            'items-per-page-options': [50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
          <template v-slot:item.select="{ item }">
            <v-btn class="primary" @click="selecionarProjeto(item)"
              >Selecionar</v-btn
            >
          </template>

          <template v-slot:item.PRI_DTA_ABERTURA="{ item }">
            <span v-date-format="item.PRI_DTA_ABERTURA"></span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import strapiApi from "../../../../../libraries/strapi/strapiApi";
import conexosStrapiApi from "../../../../../libraries/conexos/conexosStrapiApi";

export default {
  name: "search-conta-projeto",
  props: ["value", "showError", "processRef", "process"],
  components: {},
  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: null,

      processes: [],
      filCod: 3,
      contasProjeto: [],
      pagination: {
        pageSize: 50,
        page: 1,
        itemsPerPage: 10,
        serverItemsLength: 50,
        sortBy: "",
        sortDesc: false,
      },
      selProjeto: {},
      headers: [
        {
          text: "Selecionar",
          value: "select",
          width: "20px",
          sortable: true,
        },
        {
          text: "Código",
          value: "ctpCod",
          width: "20px",
          sortable: true,
        },
        {
          text: "ctpEspConta",
          value: "ctpEspConta",
          align: "start",
          sortable: false,
        },
        {
          text: "Nome",
          value: "ctpDesNome",
          align: "start",
          sortable: false,
        },
        //{ text: "Pdf link", value: "pdfLink", sortable: false },

        // {
        //   text: "Nome pessoa",
        //   value: "DPE_NOM_PESSOA_LIG",
        //   sortable: false,
        // },

        // {
        //   text: "Selecionar",
        //   value: "action",
        //   sortable: false,
        // },

        // {
        //   text: "Valor Serviços",
        //   value: "ServicoValorServicos",
        //   sortable: false,
        // },
        //{ text: "Número", value: "Numero", sortable: false },
        // { text: "Ação", value: "action", sortable: false },
      ],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showDialog() {
      //alert("changed");
      this.search = this.processRef;
    },
  },
  async mounted() {
    // await this.doSearch();
  },
  methods: {
    selecionarProjeto(projeto) {
      this.selProjeto = projeto;
      this.$emit("selectContaProjeto", projeto);
    },

    closeError() {
      this.$emit("closeError");
    },
    closeModal() {
      this.showDialog = false;
    },

    yes() {
      if (this.containers[0] && this.containers[0].containerNumber)
        this.$emit("yes", {
          containerNumber:
            this.containers[0] && this.containers[0].containerNumber,
          blContainersRef: this.blContainersRef ? this.blContainersRef : "",
        });
      else alert("Informar container e BL");
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("do search");

      const ans = await conexosStrapiApi.getCom298ContasProjeto(
        this.pagination.page,
        this.process.FIL_COD,
        this.process.PRI_COD,
        this.search
      );

      console.log(JSON.stringify({ ans }));
      this.contasProjeto = ans?.rows;
      this.pagination.serverItemsLength = ans?.count;
      // const { values, pagination } =
      //   await strapiApi.processes.getByRefWithPagination(
      //     this.page,
      //     this.itemsPerPage,
      //     this.sortBy,
      //     this.sortDesc,
      //     this.search,
      //     this.filCod
      //   );

      // console.log(JSON.stringify({ values, pagination }));
      // this.processes = values;
      // //alert(JSON.stringify({  pagination }));
      // this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>
