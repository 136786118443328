<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container fluid>
        <v-row class="ma-2 my-4 flex">
          <h4 style="margin: auto">Buscar notas</h4>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="mx-5">
          <v-col cols="3" class="">
            <v-select
              :items="sellerNames"
              v-model="filters.chipSeller"
              label="Seller"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-text-field
              class="pb-2"
              label="Sponsor"
              v-model="filters.sponsorName"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="">
            <v-text-field
              class="pb-2"
              label="Número da nota"
              v-model="filters.offerExternalId"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="">
            <DateInput
              v-model="filters.kanastraCreatedAtFrom"
              label="Envio de"
            ></DateInput>
          </v-col>
          <v-col cols="3" class="">
            <DateInput
              v-model="filters.kanastraCreatedAtTo"
              label="Envio até"
            ></DateInput>
          </v-col>
          <v-col cols="2" class="">
            <v-btn class="primary" @click="doSearch">Buscar</v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-2 my-4 flex">
          <h4 style="margin: auto">Histórico</h4>
          <v-spacer></v-spacer>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="offers"
          item-key="name"
          class="custom-table"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
          <template v-slot:item.status="{ item }">
            {{ item.status }}
          </template>

          <template v-slot:item.invoiceNumber="{ item }">
            {{ item.offer.invoiceNumber }}
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span v-date-format="item.kanastraAcquisition?.sentAt"></span>
          </template>

          <template v-slot:item.sponsorName="{ item }">
            <span>{{ item.offer.sponsorName }}</span>
          </template>
          <template v-slot:item.sponsorGovernmentId="{ item }">
            <span>{{ item.offer.sponsorGovernmentId }}</span>
          </template>
          <template v-slot:item.paymentValue="{ item }">
            <span v-money-format="item.offer.paymentValue"></span>
          </template>

          <!-- {
          text: "Sponsor",
          align: "start",
          sortable: false,
          value: "sponsorName",
        },
        {
          text: "Sponsor doc",
          align: "start",
          sortable: false,
          value: "sponsorGovernmentId",
        },

        {
          text: "Valor total (R$)",
          align: "start",
          sortable: false,
          value: "paymentValue",
        }, -->

          <template v-slot:item.kanastraUpdatedAt="{ item }">
            <span v-date-format="item.kanastraUpdatedAt"></span>
          </template>
        </v-data-table>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import kanastraApi from "../../../../libraries/kanastra/kanastraApi";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import dateFunctions from "../../../../libraries/corsolar/dateFunctions";
import DateInput from "../../components/fields/DateInput.vue";

export default {
  name: "offers",
  components: {
    AutocompleteSelect,
    DateInput,
  },

  data() {
    return {
      menu: false,
      filters: {
        chipSeller: "",
        offerExternalId: "",
        sponsorName: "",
        kanastraCreatedAtFrom: "",
        kanastraCreatedAtTo: "",
      },
      files: [],
      jsonData: [],
      headers: ["PrestadorRazaoSocial", "DataEmissao"],

      headers: [
        //TomadorCnpj
        // {
        //   text: "Id",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        // },
        // {
        //   text: "Id",
        //   align: "start",
        //   sortable: false,
        //   value: "offerExternalId",
        // },
        {
          text: "Id Kanastra",
          align: "start",
          sortable: false,
          value: "externalId",
        },
        {
          text: "Nota fiscal",
          align: "start",
          sortable: false,
          value: "invoiceNumber",
        },
        {
          text: "Sponsor",
          align: "start",
          sortable: false,
          value: "sponsorName",
        },
        {
          text: "Sponsor doc",
          align: "start",
          sortable: false,
          value: "sponsorGovernmentId",
        },

        {
          text: "Valor total nota(R$)",
          align: "start",
          sortable: false,
          value: "paymentValue",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Data de envio",
          align: "start",
          sortable: false,
          value: "createdAt",
        },
        {
          text: "Última atualização",
          align: "start",
          sortable: false,
          value: "kanastraUpdatedAt",
        },
      ],

      offers: [],
      sortBy: "kanastraAcquisition.sentAt",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      sellers: [],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    sellerNames() {
      return this.sellers.map((el) => el.name);
    },
  },

  watch: {},
  async mounted() {
    await this.getSellers();

    await this.doSearch();
  },
  methods: {
    async getSellers() {
      let { values, pagination } = await strapiApi.sellers.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc
      );

      this.sellers = values;
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      let seller = this.sellers?.find(
        (el) =>
          this.filters.chipSeller && el.name.includes(this.filters.chipSeller)
      );

      //   sellerId,
      // sponsorName,
      // invoiceNumber,
      // disabled,
      let { values, pagination } =
        await strapiApi.kanastraOffers.getWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          seller?.id,
          this.filters.offerExternalId,
          this.filters.sponsorName,
          this.filters.kanastraCreatedAtFrom,
          this.filters.kanastraCreatedAtTo
          // false,
        );
      let offers = values;

      //alert(JSON.stringify({ pagination }));
      this.serverItemsLength = pagination?.total;

      this.offers = offers;
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
