import functions from "./functions";
var offers = {
  async auth() {},

  async post(boleto) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/offers`;
    let data = boleto;

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
  async upsert(boleto) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/boleto/upsert`;
    let data = boleto;

    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    com304created
  ) {
    const start = Date.now();

    var filters = {
      and: [
        {
          BeneficiarioNome: { contains: filterName },
        },
        //{ com304created: { eq: com304created ?? null } },
        // {
        //   docCod: { ne: -1 },
        // },
      ],
    };

    if (com304created) {
      filters.and.push({ com304created: { eq: com304created } });
    }
    console.log(`${sort}${sortDesc ? ":desc" : ""}`);

    var query = `query offers($filters:BoletoFiltersInput!){
      offers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            createdAt
            filCod
            docCod
            info
            amount
            BeneficiarioNome
            BeneficiarioNumero
            CodigoDeBarras
            dueDate
            issueDate
            amountInCents
            ControleBanco
            NumeroDoDocumento
            com304created
            com304createdAt
            fileName
            federalDoc
            nfse{
              data{
                id
                attributes{
                  Numero
                  TomadorCnpj
                }
              }
            }
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
         
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      });

    const end = Date.now();
    console.log(
      `Execution getWithPagination offers time: ${(end - start) / 1000} s`
    );

    const pagination = res?.data?.offers?.meta?.pagination;
    const values = functions.sanitize(res.data.offers.data);
    //console.log(JSON.stringify({ values }));
    return { values, pagination };
  },

  async put(boleto) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/offers/${boleto.id}`;
    let data = boleto;

    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },
};

export default offers;
