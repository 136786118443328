import functions from "./functions";
var installments = {
  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    sellerId,
    sponsorName,
    invoiceNumber
  ) {
    const start = Date.now();

    // let filters = {
    //   and: [
    //     {
    //       or: [
    //         { offer: { disabled: { eq: null } } },
    //         { offer: { disabled: { eq: false } } },
    //       ],
    //     },
    //   ],
    // };

    let filters = {
      offer: {
        // or: [{ disabled: { null: true } }, { disabled: { eq: false } }],
        disabled: { eq: false },
      },
    };

    if (sellerId) {
      filters.seller = { id: { eq: sellerId } };
    }

    if (sponsorName) {
      filters.sponsorName = {
        containsi: sponsorName,
      };
    }

    if (invoiceNumber) {
      filters.or = [
        {
          offerExternalId: {
            containsi: invoiceNumber,
          },
        },
        {
          invoiceNumber: {
            containsi: invoiceNumber,
          },
        },
      ];
    }

    let d = new Date();

    filters.installmentDueDate = { gt: d.toISOString().substring(0, 10) };

    // offer fields
    // offerExternalId
    // sponsorName
    // sponsorPersonType
    // sponsorGovernmentId
    // sponsorExternalCode
    // sponsorAddress
    // sponsorAddressNumber
    // sponsorAddressComplement
    // sponsorNeighborhood
    // sponsorCity
    // sponsorState
    // sponsorCountry
    // sponsorZipCode
    // sponsorBank
    // sponsorAgency
    // sponsorAgencyDigit
    // sponsorAccount
    // sponsorAccountDigit
    // totalInstallments

    var query = `query installments($filters:InstallmentFiltersInput!){
      installments( sort:"offerExternalId,paymentDate:desc" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            offerExternalId
            sponsorName
            sponsorPersonType
            sponsorGovernmentId
            sponsorAddress
            sponsorAddressNumber
            sponsorNeighborhood
            sponsorAddressComplement
            sponsorCity
            sponsorState
            sponsorCountry
            sponsorZipCode
            sponsorBank
            sponsorAgency
            sponsorAgencyDigit
            sponsorAccount
            sponsorAccountDigit
            assetType
            invoiceNumber
            invoiceDate
            invoiceKey
            totalInstallments
            paymentValue
            paymentDate
            installmentExternalId
            installmentDueDate
            installmentAmount
            totalUpdates
            offer{
              data{
                id
                attributes{
                  fileContent
                  fileJson
                  invoiceKey
                  kanastraStatus
                  kanastraRequestCount
                  disabled
                  updateNumber
                  kanastraOffers{
                    data{
                      id
                      attributes{
                        kanastraJson
                        status
                      }
                    }
                  }
                  installments{
                    data{
                      id
                      attributes{
                        installmentDueDate
                      }
                    }
                  }
                }
              }
            }
            seller{
              data{
                id
                attributes{
                  name
                  city
                  state
                  governmentId
                  prefix
                }
              }
            }
          }
          
        }
     
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination installments time: ${
          (end - start) / 1000
        } s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.installments?.meta?.pagination;
    const values = functions.sanitize(res.data.installments.data);
    return { values, pagination };
  },

  async getById(nfseId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/installments?filters[id]=${nfseId}&populate=nfse_servicos,process`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res.data);

    if (values && values.length > 0) return values[0];
    else return null;
  },

  async getByFilters(page, pageSize, sort, sortDesc, filters) {
    const start = Date.now();

    // var filters = {};

    // if (providerName) {
    //   filters = {
    //     or: [
    //       {
    //         PrestadorRazaoSocial: {
    //           contains: providerName,
    //         },
    //       },
    //     ],
    //   };
    // }
    var query = `query installments($filters:NfseFiltersInput!){
      installments( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    // console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    // console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination installments time: ${
          (end - start) / 1000
        } s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.installments?.meta?.pagination;
    const values = functions.sanitize(res.data.installments.data);
    return { values, pagination };
  },

  async put(installment) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/installments/${installment.id}`;
    let data = installment;
    console.log("put");
    console.log(url);
    console.log(JSON.stringify({ installment }));
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },
  async post(installment) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/installments`;
    let data = installment;
    console.log(`POST:${url} `);
    console.log(JSON.stringify({ installment }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },

  async sendBatch(data) {
    const start = Date.now();

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra/proxy`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: "/api/credit-originators/bancor-fidc-rlrc/offers/batch",
        // url: "/api/credit-originators/bancor-fidc-rlrc/offers",
        method: "POST",
        env: ` ${process.env.VUE_APP_ENV}`,
        ...data,
      }),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination installments time: ${
          (end - start) / 1000
        } s`
      );

    return res;
  },

  async sendManyBatch(acquisitions) {
    const start = Date.now();

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra-acquisition/sendMany`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        acquisitions,
      }),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination installments time: ${
          (end - start) / 1000
        } s`
      );

    return res;
  },
  async send(data) {
    const start = Date.now();

    var url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra/proxy`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: "/api/credit-originators/bancor-fidc-rlrc/offers",
        // url: "/api/credit-originators/bancor-fidc-rlrc/offers",
        method: "POST",
        env: ` ${process.env.VUE_APP_ENV}`,
        ...data,
      }),
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    // console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination installments time: ${
          (end - start) / 1000
        } s`
      );

    return res;
  },
};

export default installments;
