<template>
  <v-app id="inspire">
    <v-dialog v-model="showDialog">
      <v-container class="ma-0 pa-0" fluid>
        <v-card class="ma-0 pa-0">
          <v-toolbar class="primary" style="color: white">
            <p>Opções COM 298 - filial - {{ this.selBoleto?.filial?.id }}</p>
            <v-progress-circular
              v-show="selectingCom298"
              indeterminate
              color="primary"
            ></v-progress-circular>
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="showDialog = false">X</v-btn>
          </v-toolbar>
          <v-card-text class="ma-2">
            <v-row>
              <v-col cols="6"></v-col>

              <v-col cols="6">
                <div>
                  <v-btn class="primary" @click="notFoundCom298(selBoleto)"
                    >Não encontrado</v-btn
                  >

                  <v-text-field v-model="info" label="Info"> </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="ma-2">
            <v-row>
              Filtros
              <v-switch
                style="color: black"
                v-for="(option, index) in optionsFilterCom298"
                :key="index"
                :label="option.text"
                v-model="option.value"
                @change="com298List"
              ></v-switch>
            </v-row>
          </v-card-text>

          <v-card-text>
            <!-- <p>
              {{ JSON.stringify({ selBoleto }) }}
            </p> -->
            <v-row>
              <v-col>
                <GridField label="Nome" :value="selBoleto.BeneficiarioNome">
                </GridField>
              </v-col>
              <v-col>
                <GridField
                  label="Data emissão"
                  :value="formatDate(selBoleto.issueDate)"
                >
                </GridField>
              </v-col>
              <v-col>
                <GridField
                  label="Data Vencimento"
                  :value="formatDate(selBoleto.dueDate)"
                >
                </GridField>
              </v-col>
              <v-col>
                <GridField
                  label="Documento"
                  :value="selBoleto.NumeroDoDocumento"
                >
                </GridField>
              </v-col>
              <v-col>
                <GridField label="Valor" :value="formatMoney(selBoleto.amount)">
                </GridField>
              </v-col>
              <!-- <v-text-field
                label="Nome"
                v-model="selBoleto.BeneficiarioNome"
              ></v-text-field> -->
            </v-row>

            <br />
            <br />

            <v-progress-circular
              v-show="loadingCom298"
              indeterminate
              color="primary"
            ></v-progress-circular>

            <v-data-table
              :headers="headersCom298"
              :items="com298s"
              hide-default-footer
              item-key="raw"
              class="custom-table elevation-1 font-weight-black elevation-1"
              color="blue"
              ref="dataTable"
            >
              <template v-slot:item.select="{ item }">
                <v-btn class="primary" @click="selectCom298(item, selBoleto)">
                  Select</v-btn
                >
              </template>
              <template v-slot:item.docDtaEmissao="{ item }">
                <span v-date-format="item.docDtaEmissao"></span>
              </template>

              <template v-slot:item.docDtaMovimento="{ item }">
                <span v-date-format="item.docDtaMovimento"></span>
              </template>

              <template v-slot:item.docMnyValor="{ item }">
                <span v-money-format="item.docMnyValor"></span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-container>
    </v-dialog>

    <!--  <SearchNfseDialog v-model="showNfseDialog"></SearchNfseDialog> -->

    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container fluid>
        <v-row>
          <h4 style="margin: auto">Associação de boletos</h4>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="bulkSearchNfses">
            <v-progress-circular
              v-show="loadingBulkNfses"
              indeterminate
              color="white"
            ></v-progress-circular
            >Busca de NFSE's em massa</v-btn
          >

          <v-btn class="primary" @click="bulkSearchCom298">
            <v-progress-circular
              v-show="loadingBulk"
              indeterminate
              color="white"
            ></v-progress-circular
            >Busca de doc a pagar em massa</v-btn
          >

          <v-btn class="primary" @click="saveBarCodes">
            <v-progress-circular
              v-show="loadingBarcodes"
              indeterminate
              color="white"
            ></v-progress-circular
            >Cadastrar códigos de barras</v-btn
          >

          <v-btn class="primary" @click="exportToExcel"> Export</v-btn>
        </v-row>

        <br />
        <br />

        <v-row class="ma-4">
          <v-text-field
            label="Fornecedor"
            placeholder="Fornecedor nome"
            v-model="searchText"
            @blur="doSearch"
          ></v-text-field>

          <v-btn class="primary" @click="doSearch">Buscar</v-btn>
        </v-row>
        <br />

        <v-row>
          <v-container fluid>
            <v-data-table
              :headers="headers"
              :items="boletos"
              item-key="raw"
              class="custom-table elevation-1 font-weight-black elevation-1"
              color="blue"
              :server-items-length.sync="totalItems"
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100],
              }"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              @update:items-per-page="updateRowsPerPage"
              @update:page="updatePage"
              ref="dataTable"
            >
              <template v-slot:item.searchNfse="{ item }">
                <v-btn class="primary" @click="doSearchNfse(item)"
                  >Buscar nota</v-btn
                >
              </template>

              <template v-slot:item.nfse="{ item }">
                <span>{{ item?.nfse?.id }}</span>
              </template>

              <template v-slot:item.issueDate="{ item }">
                <span v-date-format="item.issueDate"></span>
              </template>
              <template v-slot:item.dueDate="{ item }">
                <span v-date-format="item.dueDate"></span>
              </template>

              <template v-slot:item.amount="{ item }">
                <span v-money-format="item.amount"></span>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="item?.nfse?.id"
                  class="primary"
                  @click="openDialogCom298(item)"
                  >Buscar Com298</v-btn
                >
              </template>
              <template v-slot:item.docCod="{ item }">
                {{ item.docCod }}
              </template>

              <template v-slot:item.actionDocCod="{ item }">
                <span v-if="item.docCod == -1" style="background-color: red">{{
                  item.docCod
                }}</span>
                <div>
                  <v-btn class="green" @click="com308put(item)">
                    Barcode-> Conexos
                    <v-icon v-if="item.com304created">mdi-check-circle </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-container>
        </v-row>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import conexosStrapiApi from "../../../../libraries/conexos/conexosStrapiApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import GridField from "../../components/fields/GridField.vue";
import moment from "moment";
import rawCom308 from "./com308";
import { read, utils, writeFile } from "xlsx";
import SearchNfseDialog from "./SearchNfseDialog.vue";

export default {
  name: "Boletos",
  components: {
    GridField,
    SearchNfseDialog,
  },
  data() {
    return {
      showNfseDialog: true,
      files: [],
      boletos: [],
      selBoleto: {},
      loadingCom298: false,
      loadingBulk: false,
      loadingBulkNfses: false,
      loadingBarcodes: false,
      saving: false,
      onlyComplete: false,
      showDialog: false,
      selectingCom298: false,
      info: "",
      optionsFilterCom298: [
        { text: "Data de emissão", field: "docDtaEmissao", value: false },
        { text: "Doc. Federal", field: "pdcDocFederal", value: true },
        { text: "Valor (R$)", field: "docMnyValor", value: true },
      ],
      providersNames: [],
      allboletos: [],
      filteredBoletos: [],
      page: 1,
      sortBy: "issueDate",
      sortDesc: true,
      page: 1,
      itemsPerPage: 25,
      searchText: "",
      totalItems: 0,
      itemsPerPageOptions: [
        { value: 10, title: "10" },
        { value: 25, title: "25" },
        { value: 50, title: "50" },
        { value: 100, title: "100" },
      ],
      nfses: [],
      headers: [
        { text: "id", value: "id", showExcel: false },
        { text: "Buscar nota", value: "searchNfse" },
        { text: "Nota", value: "nfse" },
        { text: "Arquivo", value: "fileName" },
        { text: "Search", value: "action" },
        { text: "Cod. doc pagar", value: "docCod", showExcel: true },
        { text: "Filial", value: "filCod", showExcel: true },
        { text: "Ação", value: "actionDocCod", sortable: false },
        { text: "Cadastrado", value: "com304created", showExcel: true },
        { text: "Fornecedor", value: "BeneficiarioNome", showExcel: true },
        { text: "Cnpj beneficiário", value: "federalDoc", showExcel: true },
        { text: "Código de barras", value: "CodigoDeBarras", showExcel: true },
        { text: "Valor", value: "amount", showExcel: true },
        { text: "Emissão", value: "issueDate", showExcel: true },
        { text: "Vencimento", value: "dueDate", showExcel: true },
        { text: "Documento", value: "NumeroDoDocumento", showExcel: true },
      ],
      com298s: [],
      headersCom298: [
        { text: "Selecionar", value: "select", sortable: false },
        { text: "docCod", value: "docCod", sortable: false },
        { text: "Data emissão", value: "docDtaEmissao", sortable: false },
        { text: "Data movimento", value: "docDtaMovimento", sortable: false },
        { text: "Nome pessoa", value: "dpeNomPessoa", sortable: false },
        { text: "tpdDesNome", value: "tpdDesNome", sortable: false },
        { text: "Doc", value: "docEspNumero", sortable: false },
        { text: "Valor (R$)", value: "docMnyValor", sortable: false },
      ],

      filiais: [
        { id: 1, cnpj: "05289245000102" },
        { id: 2, cnpj: "05289245000285" },
        { id: 3, cnpj: "05289245000366" },
      ],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  watch: {
    optionsFilterCom298(oldVal, newVal) {
      alert("changed");
    },
  },
  async mounted() {
    await this.doSearch();
  },
  methods: {
    async bulkSearchNfses() {
      this.loadingBulkNfses = true;
      let boletos = this.boletos;
      for (let i = 0; i < boletos.length; i++) {
        let boleto = boletos[i];
        let ans = await this.doSearchNfse(boleto);

        if (ans) {
          console.log("-----------------");
          console.log(JSON.stringify({ ans }));
          console.log("found");
        } else {
          console.log("Não encontrado");
        }
      }

      this.loadingBulkNfses = false;
    },

    async doSearchNfse(boleto) {
      console.log("do search");
      console.log(JSON.stringify({ boleto }));

      var filters = {
        and: [],
      };

      if (boleto?.provider?.id) {
        filters.provider = {
          id: { eq: boleto?.provider?.id },
        };
      }
      let d = new Date(boleto?.issueDate);
      let dStart = new Date(boleto?.issueDate);
      dStart.setHours(0);
      dStart.setDate(dStart.getDate() - 7);

      let dEnd = new Date(boleto?.issueDate);
      dEnd.setHours(0);
      dEnd.setDate(dEnd.getDate() + 7);

      if (boleto?.issueDate) {
        filters.and.push({
          DataEmissao: { gte: dStart.toISOString() },
        });
        filters.and.push({
          DataEmissao: { lte: dEnd.toISOString() },
        });
      }

      if (boleto?.amount) {
        filters.and.push({
          or: [
            { ServicoValorServicos: { eq: boleto?.amount } },
            { ValorLiquidoNfse: { eq: boleto?.amount } },
          ],
        });
      }

      console.log(JSON.stringify({ filters }));
      const { values, pagination } = await strapiApi.nfses.getByFilters(
        1,
        10,
        "DataEmissao",
        false,
        filters
      );

      console.log(JSON.stringify({ values, pagination }));

      let nfses = values;

      let nfse = this.findNfse(boleto, nfses);

      if (nfse) {
        //alert(JSON.stringify({ nfse }));

        let data = { id: boleto.id };

        data.nfse = nfse.id;

        let ans = await strapiApi.boletos.put(data);

        boleto.nfse = nfse;

        this.boletos = this.boletos.map((el) =>
          el.id == ans.id ? boleto : el
        );
      } else {
        if (boleto?.nfse?.id) {
          alert("Não mais encontrado");
        }
      }
    },
    findNfse(boleto, nfses) {
      if (nfses?.length == 1) {
        console.log(nfses[0].id);
        return nfses[0];
      }
      if (nfses?.length > 0) {
        let filteredNfses;

        //Por data
        let issueDate = new Date(boleto?.issueDate);
        filteredNfses = nfses.filter((el) => {
          let d = new Date(el.DataEmissao);
          let diff = d.getTime() - issueDate.getTime();
          if (diff < 0) return diff > -24 * 60 * 60 * 1000;
          else return diff < 24 * 60 * 60 * 1000;
        });
        if (filteredNfses?.length == 1) {
          console.log("Por data 1 dia");
          console.log(filteredNfses[0].id);
          return filteredNfses[0];
        }

        //Por data maior
        filteredNfses = nfses.filter((el) => {
          let d = new Date(el.DataEmissao);
          let diff = d.getTime() - issueDate.getTime();
          if (diff < 0) return diff > -2 * 24 * 60 * 60 * 1000;
          else return diff < 2 * 24 * 60 * 60 * 1000;
        });
        if (filteredNfses?.length == 1) {
          console.log("Por data 2 dias");
          console.log(filteredNfses[0].id);
          return filteredNfses[0];
        }

        //Buscar por documento

        filteredNfses = nfses.filter((el) =>
          el.Numero?.includes(boleto.NumeroDoDocumento)
        );
        if (filteredNfses?.length == 1) {
          console.log(filteredNfses[0].id);
          return filteredNfses[0];
        }

        filteredNfses = nfses.filter((el) =>
          boleto.NumeroDoDocumento?.includes(el.Numero)
        );
        if (filteredNfses?.length == 1) {
          console.log(filteredNfses[0].id);
          return filteredNfses[0];
        }
      }
      return null;
    },

    async exportToExcel() {
      let fields = this.headers.filter((el) => el.showExcel);

      let data = this.boletos.map((el) => {
        // id: el.id,
        // CodigoDocPagar: el.docCod,
        // Cadastrado: el.com304created,
        // Fornecedor: el.BeneficiarioNome,
        // Cnpj: el.BeneficiarioNumero,
        let row = {};
        for (let i = 0; i < fields.length; i++) {
          row[fields[i].text] = el[fields[i].value];
        }
        return row;
      });

      // const max_width = this.processes.reduce(
      //   (w, r) => Math.max(w, r.ARMAZEM_BL && r.ARMAZEM_BL.length),
      //   10
      // );
      const ws = utils.json_to_sheet(data);

      const wb = utils.book_new();

      // const headerStyle = {
      //   font: { bold: true },
      //   alignment: { horizontal: "center" },
      // };
      // utils.sheet_add_json(ws, data, { origin: -1 });

      // ws["!cols"] = [{ wch: max_width }];

      utils.book_append_sheet(wb, ws, "Boletos");

      writeFile(wb, "Boletos.xlsx");
    },
    formatMoney(value) {
      return new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        style: "currency",
        minimumFractionDigits: 2,
      }).format(value || 0);
    },
    formatDate(value) {
      let d = new Date(value);
      d.setHours(d.getHours() + 3);
      return moment(d).format("DD/MM/YYYY");
    },
    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      //alert(this.searchText?.toUpperCase());
      let { values, pagination } = await strapiApi.boletos.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        this.searchText?.toUpperCase()
      );
      console.log(JSON.stringify({ pagination }));
      this.totalItems = pagination.total;
      this.boletos = values?.map((boleto) => ({
        ...boleto,
        filial: this.filiais.find(
          (filial) => filial.cnpj == boleto?.nfse?.TomadorCnpj
        ),
      }));

      // {...el , filial: this.filiais.find(
      //   (el) => el.cnpj == this.selBoleto?.nfse?.TomadorCnpj
      // )
      // let filial = this.filiais.find(
      //   (el) => el.cnpj == this.selBoleto?.nfse?.TomadorCnpj
      // );
    },

    async notFoundCom298(boleto) {
      this.selectingCom298 = true;
      let data = { id: boleto.id };

      data.docCod = -1;
      data.info = this.info;

      let ans = await strapiApi.boletos.put(data);

      this.boletos = this.boletos.map((el) => (el.id == ans.id ? ans : el));

      this.selectingCom298 = false;
      this.showDialog = false;
      this.info = "";
      // console.log(JSON.stringify({ans}));
    },

    async selectCom298(com298, boleto) {
      this.selectingCom298 = true;
      let data = { id: boleto.id };

      data.docCod = com298.docCod;
      data.filCod = com298.filCod;

      console.log(JSON.stringify({ data }));

      let ans = await strapiApi.boletos.put(data);

      this.boletos = this.boletos.map((el) =>
        el.id == ans.id ? { ...el, ...ans } : el
      );

      console.log(JSON.stringify({ ans }));

      this.selectingCom298 = false;
      this.showDialog = false;

      // console.log(JSON.stringify({ans}));
    },

    openDialogCom298(item) {
      this.selBoleto = item;

      let filial = this.filiais.find(
        (el) => el.cnpj == this.selBoleto?.nfse?.TomadorCnpj
      );

      if (filial) {
        this.selBoleto.filial = filial;
        this.showDialog = true;
        this.info = item.info;
        this.com298List();
      } else {
        alert("Filial não encontrada");
      }
    },

    async com298List() {
      let item = this.selBoleto;

      this.com298s = [];
      this.loadingCom298 = true;

      let issueDate = new Date(item.issueDate);
      let federalDoc = item?.BeneficiarioNumero?.substring(
        1,
        item?.BeneficiarioNumero?.length
      );
      let amount = item.amount;
      let filterList = {
        // "docDtaEmissao#LE": issueDate.getTime(),
        "vldStatus#IN": ["1", "3"],
        //"vldStatus#IN": ["1", "3", "5", "6"],
        // "pdcDocFederal#LIKE": federalDoc,
        // "docMnyValor#EQ": amount,
      };

      // { text: "Data de emissão", field: "docDtaEmissao", value: false },
      //   { text: "Doc. Federal", field: "pdcDocFederal", value: true },
      //   { text: "Valor (R$)", field: "docMnyValor", value: true },
      for (let i = 0; i < this.optionsFilterCom298.length; i++) {
        let option = this.optionsFilterCom298[i];
        if (option.field == "docDtaEmissao" && option.value) {
          filterList["docDtaEmissao#EQ"] = issueDate.getTime();
        }
        if (option.field == "pdcDocFederal" && option.value) {
          filterList["pdcDocFederal#LIKE"] = federalDoc;
        }
        if (option.field == "docMnyValor" && option.value) {
          filterList["docMnyValor#EQ"] = amount;
        }
      }

      console.log(JSON.stringify({ filterList }));
      let ans = await conexosStrapiApi.com298List(
        this.selBoleto?.filial?.id ?? 1,
        filterList
      );

      console.log(JSON.stringify({ count: ans.count }));

      console.log(JSON.stringify({ pageNumber: ans.pageNumber }));

      //console.log(JSON.stringify({ rows: ans.rows }));

      if (ans?.rows) {
        this.com298s = ans?.rows;
      }
      this.loadingCom298 = false;
    },

    async bulkSearchCom298() {
      this.loadingBulk = true;
      let boletos = this.boletos;
      for (let i = 0; i < boletos.length; i++) {
        let boleto = boletos[i];
        if (boleto?.filial) {
          let ans = await this.com298SearchExact(boleto);

          if (ans) {
            await this.selectCom298(ans, boleto);
          } else {
            console.log("Não encontrado");
          }
        } else {
          console.log("Sem filial");
        }
      }

      this.loadingBulk = false;
    },

    // data.docCod = -1;
    //   data.info = this.info;

    //   let ans = await strapiApi.boletos.put(data);

    //   this.boletos = this.boletos.map((el) => (el.id == ans.id ? ans : el));

    async com298SearchExact(boleto) {
      this.com298s = [];
      this.loadingCom298 = true;

      let issueDate = new Date(boleto.issueDate);
      let federalDoc = boleto?.BeneficiarioNumero?.substring(
        1,
        boleto?.BeneficiarioNumero?.length
      );
      let amount = boleto.amount;
      let filterList = {
        "docDtaEmissao#EQ": issueDate.getTime(),
        //  "vldStatus#IN": ["1", "3", "5", "6"],
        "vldStatus#IN": ["1", "3"],
        "pdcDocFederal#LIKE": federalDoc,
        "docMnyValor#EQ": amount,
      };

      let ans = await conexosStrapiApi.com298List(
        boleto?.filial?.id ?? 1,
        filterList
      );

      let docs = ans?.rows;

      if (docs?.length > 0) {
        if (docs.length == 1) {
          return docs[0];
        }

        let docsFilter = docs.filter((el) =>
          el.docEspNumero.includes(boleto.NumeroDoDocumento)
        );
        if (docsFilter?.length == 1) return docsFilter[0];

        docsFilter = docs.filter((el) =>
          boleto.NumeroDoDocumento.includes(el.docEspNumero)
        );
        if (docsFilter?.length == 1) return docsFilter[0];

        //Try to find exact
      }
      return this.com298SearchDateNear(boleto);
      //return null;
    },

    async saveBarCodes() {
      this.loadingBarcodes = true;
      for (let a = 0; a < this.boletos.length; a++) {
        //for (let a = 0; a < 4; a++) {
        let boleto = this.boletos[a];

        let ans = await this.com308put(boleto);
      }
      this.loadingBarcodes = false;
    },

    async com308put(item) {
      // com308.docCod = item.docCod;
      // com308.itsMnyVlrPgto = item.amount;
      // com308.itsDtaPgto = new Date(`item.dueDateT03:00:00.000Z`).getTime();
      // com308.itsNumCodbar = item.CodigoDeBarras;

      if (!item?.filCod || !item?.docCod) {
        console.log("Sem documento a pagar");
        return;
      }

      if (item.com304created) {
        console.log("Com304 já criado");
        return;
      }

      let dataCodigoBarras = {
        bncCodLote: null,
        itsMnyVlrPgto: item.amount,
        itsVldModalidade: 6,
        titDtaVencimento: new Date(`${item.dueDate}T03:00:00.000Z`).getTime(),
        itsNumCodbar: item.CodigoDeBarras,
        titMnyJuros: 0,
        titMnyLiquido: 0,
        titMnyDesconto: 0,
      };

      let rawDataCodigoBarras = {
        bncCodLote: null,
        itsMnyVlrPgto: 1292.5,
        itsVldModalidade: 6,
        titDtaVencimento: 1703030400000,
        itsNumCodbar: "34195957000001292501090007971732938434483000",
        titMnyJuros: 0,
        titMnyLiquido: 0,
        titMnyDesconto: 0,
      };

      console.log(JSON.stringify({ dataCodigoBarras, rawDataCodigoBarras }));

      let ansCodBarras = await conexosStrapiApi.validaCodigoBarras(
        dataCodigoBarras
      );

      console.log(JSON.stringify({ ansCodBarras }));

      let responseData = ansCodBarras?.responseData;

      let dataConfirmaCodigoBarras = {
        titMnyLiquido: responseData.barraVlrPgto,
        titMnyJuros: 0,
        itsMnyVlrPgto: responseData.barraVlrPgto,
        titMnyDesconto: 0,
        bncCodLote: null,
        itsVldModalidade: 6,
        titDtaVencimento: responseData.barraDtaVencimento,
        itsNumCodbar: item.CodigoDeBarras,
        splitCodBar: responseData.splitCodBar,
        barraVlrPgto: responseData.barraVlrPgto,
      };

      let ansConfirmaCodigoBarras = await conexosStrapiApi.confirmaCodigoBarras(
        dataConfirmaCodigoBarras
      );
      console.log(JSON.stringify({ ansConfirmaCodigoBarras }));

      if (ansConfirmaCodigoBarras?.itsNumCodbar) {
        let com308 = rawCom308;

        com308.filCod = item?.filCod ?? 3;
        com308.docCod = item.docCod;
        com308.itsMnyVlrPgto = item.amount;
        com308.itsDtaPgto = new Date(`${item.dueDate}T03:00:00.000Z`).getTime();
        com308.itsNumCodbar = item.CodigoDeBarras;
        com308.bncDesNome = ansConfirmaCodigoBarras.itsBncDesNomeResult;
        com308.itsNumBanco = ansConfirmaCodigoBarras.itsBncNumCodbancoResult;
        console.log(JSON.stringify(com308));

        let ans = await conexosStrapiApi.com308put(com308);

        console.log(JSON.stringify({ ans }));

        if (ans?.docCod) {
          let updateData = { id: item.id };
          let d = new Date();
          updateData.com304created = true;
          updateData.com304createdAt = d.toISOString();

          let ansBoleto = await strapiApi.boletos.put(updateData);
          console.log(JSON.stringify({ ansBoleto }));

          this.boletos = this.boletos.map((el) =>
            el.id == ansBoleto.id ? { ...el, ...ansBoleto } : el
          );
        }
      }
    },

    async com298SearchDateNear(boleto) {
      console.group("com298SearchDateNear");
      this.com298s = [];
      this.loadingCom298 = true;

      let issueDate = new Date(boleto.issueDate);
      let federalDoc = boleto?.BeneficiarioNumero?.substring(
        1,
        boleto?.BeneficiarioNumero?.length
      );
      let amount = boleto.amount;
      let filterList = {
        "docDtaEmissao#LE": issueDate.getTime() + 2 * 24 * 60 * 60 * 1000,
        "docDtaEmissao#GE": issueDate.getTime() - 2 * 24 * 60 * 60 * 1000,

        "vldStatus#IN": ["1", "3", "5", "6"],
        "pdcDocFederal#LIKE": federalDoc,
        "docMnyValor#EQ": amount,
      };

      console.log(JSON.stringify({ filterList }));
      let ans = await conexosStrapiApi.com298List(
        boleto?.filial?.id ?? 1,
        filterList
      );

      let docs = ans?.rows;

      if (docs?.length > 0) {
        if (docs.length == 1) {
          return docs[0];
        }

        let docsFilter = docs.filter((el) =>
          el.docEspNumero.includes(boleto.NumeroDoDocumento)
        );
        if (docsFilter?.length == 1) return docsFilter[0];

        docsFilter = docs.filter((el) =>
          boleto.NumeroDoDocumento.includes(el.docEspNumero)
        );
        if (docsFilter?.length == 1) return docsFilter[0];

        //Try to find exact
      }
      return null;
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
