<template>
  <v-card class="pa-10">
    <v-card-text>
      <v-row>
        <!-- <v-col>
          <v-select
            :items="itemsCom298"
            label="Tipo de documento"
            v-model="com342.com298"
          ></v-select>
        </v-col> -->

        <v-col>
          <v-text-field v-model="com342.docEspNumero" label="Número da nota">
          </v-text-field>
        </v-col>

        <!-- <v-col>
          <v-text-field v-model="com342.processRef" label="Referência">
          </v-text-field>
        </v-col> -->
        <!-- <v-col>
          <v-text-field v-model="com342.priCod" label="Processo">
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field v-model="com342.filCod" label="Filial">
          </v-text-field>
        </v-col> -->
        <v-col>
          <p v-if="com342.docCod" style="color: green">
            {{ com342?.docCod }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardCom298",
  props: ["optionsCom298", "com342"],
  data() {
    return {
      formattedNumber: "",
      numericInput: "",
    };
  },
  components: {},
  computed: {
    itemsCom298() {
      return this.optionsCom298?.map((el) => ({
        value: el,
        text: el.tpdDesNome,
      }));
    },
  },
  async mounted() {},
  methods: {
    handleInput() {},
  },
};
</script>

<style></style>
