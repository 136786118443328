let com308 = {
  borCod: null,
  conta: null,

  docEspNumero: null,
  docTip: 2,
  fbtCod: null,
  fbtDesDescr: null,
  fbtEspCodbanco: null,
  filCod: 1,
  frontModelName: "modeloTitulos",
  itsCodReceita: null,
  itsDtaApuracao: null,

  itsEspMyid: null,

  itsEspNumref: null,
  itsMnyAtm: null,
  itsMnyOutros: null,
  itsMnyRecbrac: null,

  itsNumDvLacreFgts: null,
  itsNumIdFgts: null,
  itsNumLacreFgts: null,
  itsNumSrf: null,
  itsPctRecbruac: null,
  itsVldModalidade: 6,
  itsVldTributo: 0,
  itsVldTributoIsBarras: null,
  pctCodSeq: null,

  titCod: 1,
  titDesChavePix: null,
  titDtaVencimento: null,

  titMnyDesconto: null,
  titMnyJuros: null,
  titMnyValor: null,
  titVldChavePix: null,
  usnCod: null,
  vldBordero: 0,
  //Hided
  //"titEspNumero": "3782211",
  // "pesCod": 5373,
  //"itsNumBanco": 341,
  //"bncCod": 5,
  //"bncDesNome": "BANCO ITAU S.A",
  // priCod: 1692,
  // priEspRefcliente: "VIX00838/23",

  //Edited

  //"itsDtaPgto": 1703030400000,
  //"itsNumCodbar": "34199957000000622901570000273700070666680000",
  //"itsMnyVlrPgto": 622.9,
  //"docCod": 32384,
  docCod: 32383,
  itsMnyVlrPgto: 3479.01,
  itsDtaPgto: 1673308800000,
  itsNumCodbar: "00191959100003479010000003436101000343740417",
};

export default com308;
