import functions from "./functions";
var offers = {
  async put(offer) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/offers/${offer.id}`;
    let data = offer;
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },

  async post(offer) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/offers`;
    let data = offer;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    kanastraSent,
    sellerId,
    sponsorName,
    invoiceNumber,
    disabled,
    kanastraCreatedAtFrom,
    kanastraCreatedAtTo
  ) {
    const start = Date.now();

    var filters = {
      disabled: { eq: disabled ?? false },
    };

    if (kanastraSent) {
      filters.kanastraUuid = {
        ne: null,
      };
    }

    if (sellerId) {
      filters.seller = { id: { eq: sellerId } };
    }

    if (sponsorName) {
      filters.sponsorName = {
        containsi: sponsorName,
      };
    }

    if (invoiceNumber) {
      filters.or = [
        {
          offerExternalId: {
            containsi: invoiceNumber,
          },
        },
        {
          invoiceNumber: {
            containsi: invoiceNumber,
          },
        },
      ];
    }

    // kanastraCreatedAtFrom ,
    // kanastraCreatedAtTo
    filters.and = [];
    if (kanastraCreatedAtFrom) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let kanastraCreatedDateFrom = new Date(kanastraCreatedAtFrom);
      //kanastraCreatedDateFrom.setHours(kanastraCreatedDateFrom.getHours() + 24);
      filters.and.push({
        kanastraCreatedAt: {
          gte: kanastraCreatedDateFrom.toISOString(),
        },
      });
    }

    if (kanastraCreatedAtTo) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let kanastraCreatedDateTo = new Date(kanastraCreatedAtTo);
      kanastraCreatedDateTo.setHours(kanastraCreatedDateTo.getHours() + 24);
      filters.and.push({
        kanastraCreatedAt: {
          lte: kanastraCreatedDateTo.toISOString(),
        },
      });
    }

    console.log(JSON.stringify({ filters }));

    var query = `query offers($filters:OfferFiltersInput!){
      offers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            offerExternalId
            sponsorName
            sponsorPersonType
            sponsorGovernmentId
            sponsorAddress
            sponsorAddressNumber
            sponsorNeighborhood
            sponsorAddressComplement
            sponsorCity
            sponsorState
            sponsorCountry
            sponsorZipCode
            sponsorBank
            sponsorAgency
            sponsorAgencyDigit
            sponsorAccount
            sponsorAccountDigit
            assetType
            invoiceNumber
            invoiceDate
            invoiceKey
            totalInstallments
            paymentValue
            paymentDate
            kanastraStatus
            kanastraCreatedAt
            kanastraUpdatedAt
            kanastraAcquisitionId
            kanastraUuid
            kanastraStatus
            kanastraCreatedAt
            kanastraUpdatedAt
            kanastraAcquisitionId
            kanastraUuid
            updateNumber
            disabled
            fileJson
            fileContent
            fileContentType
            kanastraRequestCount
            seller{
              data{
                id
                attributes{
                  name
                  city
                  state
                }
              }
            }
            com297{
              data{
                attributes{
                  docCod
                }
              }
            }
            installments{
              data{
                attributes{
                  installmentAmount
                  installmentDueDate
                }
              }
            }
          }
          
        }
     
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination offers time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.offers?.meta?.pagination;
    const values = functions.sanitize(res.data.offers.data);
    return { values, pagination };
  },
  async getById(nfseId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/offers?filters[id]=${nfseId}&populate=nfse_servicos,process`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res.data);

    if (values && values.length > 0) return values[0];
    else return null;
  },

  async getByFilters(page, pageSize, sort, sortDesc, filters) {
    const start = Date.now();

    // var filters = {};

    // if (providerName) {
    //   filters = {
    //     or: [
    //       {
    //         PrestadorRazaoSocial: {
    //           contains: providerName,
    //         },
    //       },
    //     ],
    //   };
    // }
    var query = `query offers($filters:NfseFiltersInput!){
      offers( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    // console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    // console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination offers time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.offers?.meta?.pagination;
    const values = functions.sanitize(res.data.offers.data);
    return { values, pagination };
  },
};

export default offers;
