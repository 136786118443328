<template>
  <v-container fluid>
    <SearchConfigCom298
      v-model="showSearchConfigCom298"
      :process="process"
      @selectConfigProduto="selectConfigProduto"
    ></SearchConfigCom298>
    <v-row style="align-items: flex-start">
      <v-col cols="1">
        <v-btn @click="deleteMe">-</v-btn>
      </v-col>
      <v-col cols="6">
        <v-textarea
          v-model="produto.description"
          label="Descrição"
          color="primary"
        >
        </v-textarea>

        <!-- <v-text-field
       
        ></v-text-field> -->
      </v-col>
      <v-col cols="3">
        <v-select
          :items="itemsProdutos"
          label="Produto previamente cadastrado"
          v-model="produto.refCom298Produto"
        ></v-select>
        <v-btn class="primary" @click="showSearchConfigCom298 = true">
          + Produto
        </v-btn>
      </v-col>

      <v-col cols="2">
        <div>
          <p style="font-size: 8px">Valor</p>
          <input
            style="border-style: none none solid none"
            v-model="value"
            label="Enter Number"
          />
        </div>
      </v-col>
      <!-- 
      <v-col>
        <v-btn class="primary" @click="createServiceProductMap"
          >Cadastrar map</v-btn
        >
      </v-col> -->
      <v-col>
        <p v-if="produto.saved" style="color: green">Cadastrado</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import conexosStrapiApi from "../../../../../libraries/conexos/conexosStrapiApi";
import strapiApi from "../../../../../libraries/strapi/strapiApi";
import SearchConfigCom298 from "../configCom298/SearchConfigCom298.vue";

export default {
  name: "CardProdutoCom298",
  props: ["optionsProdutos", "produto", "index", "provider", "process"],
  data() {
    return {
      formattedNumber: "",
      numericInput: "",
      showSearchConfigCom298: false,
    };
  },
  components: {
    SearchConfigCom298,
  },
  computed: {
    value: {
      get() {
        return new Intl.NumberFormat("pt-BR", {
          currency: "BRL",

          minimumFractionDigits: 2,
        }).format(this.produto.value / 100 || 0);
      },
      set(value) {
        this.produto.value = parseInt(value.replace(/[^0-9]/g, ""));
      },
    },

    itemsProdutos() {
      return this.optionsProdutos?.map((el) => ({
        value: el,
        text: el.ctpDesNome,
      }));
    },
  },
  async mounted() {},
  methods: {
    selectConfigProduto(data) {
      // alert(JSON.stringify({ data }));

      this.$emit("selectConfigProduto", { data, index: this.index });
    },
    async createServiceProductMap() {
      let data = {
        serviceDescription: this.produto.description,
        productDescription: this.produto.refCom298Produto?.ctpDesNome,
        provider: this.provider?.id,
        priVldTipo: this.process?.PRI_VLD_TIPO,
      };
      let res = await strapiApi.serviceProductMaps.post(data);

      alert(JSON.stringify(res));
    },
    deleteMe() {
      this.$emit("deleteMe", this.index);
    },
    handleInput() {},
  },
};
</script>

<style></style>
