<template>
  <v-app id="inspire">
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container fluid>
        <v-row>
          <h4 style="margin: auto">Pagamentos</h4>
          <v-btn class="primary" @click="saveOnBackend">
            <v-progress-circular
              v-show="saving"
              indeterminate
              color="white"
            ></v-progress-circular
            >Cadastrar</v-btn
          >
        </v-row>

        <!-- <v-row>
        <img
          width="600px"
          src="@/assets/img/banner4.png"
          style="margin: auto"
        />
      </v-row> -->
        <v-row>
          <v-file-input
            small-chips
            multiple
            label="Arquivos CNAB"
            v-model="files"
            @change="handleFileLoaded"
          ></v-file-input>
          <v-switch
            label="Todos os campos preenchidos"
            v-model="onlyComplete"
          ></v-switch>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="boletos"
          item-key="raw"
          class="custom-table elevation-1 font-weight-black elevation-1"
          color="blue"
          hide-default-footer
          :server-items-length.sync="boletos.length"
        >
          <!-- <template v-slot:header.ServicoCnab="{ header }">
            <div style="background-color: green">
              {{ ` ${header.text}` }}
            </div>
          </template> -->
          <template v-slot:item.ServicoCnab="{ item }">
            <div style="background-color: green">
              <p>{{ item.ServicoCnab }}</p>
            </div>
          </template>
          <template v-slot:item.Praca="{ item }">
            <div style="background-color: green">
              <p>{{ item.Praca }}</p>
            </div>
          </template>
          <template v-slot:item.Dv="{ item }">
            <div style="background-color: green">
              <p>{{ item.Dv }}</p>
            </div>
          </template>

          <template v-slot:item.NumeroDoDocumento="{ item }">
            <div style="background-color: green">
              <p>{{ item.NumeroDoDocumento }}</p>
            </div>
          </template>

          <template v-slot:item.complete="{ item }">
            <div v-if="item.complete" style="background-color: green">
              <p>{{ item.complete }}</p>
            </div>

            <div v-else style="background-color: red">
              <p>{{ item.complete }}</p>
            </div>
          </template>
          <!-- 
        key !== "ServicoCnab" &&
                  key !== "Praca" &&
                  key !== "Dv" &&
                  key !== "Cnab" &&
                  key !== "NumeroDoDocumento" && -->
        </v-data-table>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import conexosStrapiApi from "../../../../libraries/conexos/conexosStrapiApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "Finance",

  data() {
    return {
      files: [],
      boletos: [],
      saving: false,
      onlyComplete: false,

      allboletos: [],
      filteredBoletos: [],
      headers: [
        { text: "ControleBanco", value: "ControleBanco" },
        { text: "ControleLote", value: "ControleLote" },
        { text: "ControleRegistro", value: "ControleRegistro" },
        { text: "ServicoNumDoRegistro", value: "ServicoNumDoRegistro" },
        { text: "ServicoSegmento", value: "ServicoSegmento" },
        { text: "ServicoCnab", value: "ServicoCnab" },
        { text: "ServicoMovimento", value: "ServicoMovimento" },
        { text: "CodigoDeBarras", value: "CodigoDeBarras" },
        // { text: "ServicoCnab", value: "ServicoCnab"},
        // { text: "ServicoCnab", value: "ServicoCnab"},

        // ControleBanco: line.substring(0, 3),
        //     ControleLote: line.substring(3, 7),
        //     ControleRegistro: line.substring(7, 8),
        //     ServicoNumDoRegistro: line.substring(8, 13),
        //     ServicoSegmento: line.substring(13, 14),
        //     ServicoCnab: line.substring(14, 15),
        //     ServicoMovimento: line.substring(15, 17),
        //     CodigoDeBarras: line.substring(17, 61),
      ],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },

  watch: {
    // whenever question changes, this function will run
    onlyComplete(newValue, oldValue) {
      if (newValue) {
        this.boletos = this.filteredBoletos;
      } else {
        this.boletos = this.allboletos;
      }
    },
  },
  async mounted() {},
  methods: {
    checkContainsOnlySpaces(str) {
      return str.trim() === "";
    },

    onlyUnique(value, index, array) {
      //    if(value.)
      return array.findIndex((el) => el.raw == value.raw) === index;
      //  return array.indexOf(value) === index;
    },

    isValidDate(dateString) {
      // Attempt to create a Date object from the string
      const dateObject = new Date(dateString);

      // Check if the resulting object is a valid date and the input string is not NaN
      return !isNaN(dateObject.getTime()) && dateObject instanceof Date;
    },

    formatDate(dateString) {
      if (!dateString) return null;
      // Extract year, month, and day from the string
      let year = dateString.substring(4, 8);
      let month = dateString.substring(2, 4);
      let day = dateString.substring(0, 2);

      // Create a new Date object with the extracted values
      let dateObject = new Date(`${year}-${month}-${day}T03:00:00.000Z`);
      console.log(JSON.stringify({ dateObject }));
      // Check if the resulting object is a valid date and the input string is not NaN
      if (isNaN(dateObject.getTime()) && dateObject instanceof Date) {
        return null;
      } else {
        return dateObject.toISOString();
      }
    },

    async saveOnBackend() {
      let boletos = this.filteredBoletos;
      this.saving = true;
      console.log(`Cadastrar boleto`);
      for (let i = 0; i < boletos.length; i++) {
        //for (let i = 0; i < 1; i++) {
        let boleto = boletos[i];
        console.log(`Cadastrar boleto:`);
        console.log(JSON.stringify({ boleto }));
        let ans = await strapiApi.boletos.upsert(boleto);
        console.log(JSON.stringify({ ans }));
      }
      this.saving = false;
    },

    handleFileLoaded(data) {
      console.log("handleFileLoaded");
      // this.boletos = data;
      console.log(JSON.stringify({ data }));
      let files = this.files;
      this.allboletos = [];
      this.filteredBoletos = [];

      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        console.log("file:");
        console.log(JSON.stringify({ name: file?.name }));
        if (file) {
          const reader = new FileReader();

          reader.onload = (e) => {
            const content = e.target.result;
            const lines = content.split("\r\n");
            let boletos = lines.map((line) => ({
              ControleBanco: line.substring(0, 3),
              ControleLote: line.substring(3, 7),
              ControleRegistro: line.substring(7, 8),
              ServicoNumDoRegistro: line.substring(8, 13),
              ServicoSegmento: line.substring(13, 14),
              ServicoCnab: line.substring(14, 15),
              ServicoMovimento: line.substring(15, 17),
              CodigoDeBarras: line.substring(17, 61),
              BeneficiarioTipo: line.substring(61, 62),
              BeneficiarioNumero: line.substring(62, 77),
              BeneficiarioNome: line.substring(77, 107),
              Vencimento: line.substring(107, 115),

              ValorDoTitulo: line.substring(115, 130),
              //Vencimento: line.substring(115, 130),
              QtdMoeda: line.substring(130, 145),
              CodigoMoeda: line.substring(145, 147),
              NumeroDoDocumento: line.substring(147, 162),

              AgenciaCobradora: line.substring(162, 167),
              Dv: line.substring(167, 168),
              Praca: line.substring(168, 178),

              Carteira: line.substring(178, 179),

              EspecieTitulo: line.substring(179, 181),

              DataEmissao: line.substring(181, 189),

              Juros: line.substring(189, 204),

              CodDesconto: line.substring(204, 205),

              DataDesconto: line.substring(205, 213),

              Desconto: line.substring(213, 228),

              CodProtesto: line.substring(228, 229),

              PrazoProtesto: line.substring(229, 231),

              DataLimite: line.substring(231, 239),

              Cnab: line.substring(239, 240),
              fileName: file?.name,

              raw: line,
            }));

            boletos = boletos.map((el) => ({
              ...el,
              dueDate: this.formatDate(el.Vencimento),
              issueDate: this.formatDate(el.DataEmissao),
              amountInCents: parseInt(el.ValorDoTitulo),
              federalDoc: el?.BeneficiarioNumero?.substring(
                1,
                el?.BeneficiarioNumero?.length
              ),
              amount: isNaN(parseInt(el.ValorDoTitulo))
                ? 0
                : parseInt(el.ValorDoTitulo) / 100,
            }));

            let keys = Object.keys(boletos[0]);

            // Emit the JSON data to the parent component
            //this.$emit("file-loaded", boletos);

            //console.log(JSON.stringify({ boletos }));
            //this.boletos = boletos;

            let allItemsToAdd = [];
            let filteredItemsToAdd = [];
            for (let j = 0; j < boletos.length; j++) {
              let complete = true;
              let item = boletos[j];
              for (let k = 0; k < keys.length; k++) {
                let key = keys[k];
                if (
                  key !== "ServicoCnab" &&
                  key !== "Praca" &&
                  key !== "Dv" &&
                  key !== "Cnab" &&
                  key !== "NumeroDoDocumento" &&
                  key !== "amount" &&
                  key !== "dueDate" &&
                  key !== "issueDate" &&
                  key !== "amountInCents" &&
                  (!item[key] || this.checkContainsOnlySpaces(item[key]))
                ) {
                  if (complete) {
                    complete = false;
                  }
                }
              }
              item["complete"] = complete;

              allItemsToAdd.push(item);

              if (complete) {
                filteredItemsToAdd.push(item);
              }
            }

            keys.push("complete");
            this.headers = keys.map((el) => ({ text: el, value: el }));

            if (allItemsToAdd && allItemsToAdd.length > 0) {
              this.allboletos = this.allboletos.concat(allItemsToAdd);
              this.allboletos = this.allboletos.filter(this.onlyUnique);
            }
            if (filteredItemsToAdd && filteredItemsToAdd.length > 0) {
              this.filteredBoletos =
                this.filteredBoletos.concat(filteredItemsToAdd);
              this.filteredBoletos = this.filteredBoletos.filter(
                this.onlyUnique
              );

              console.log("items read");
            }

            if (this.onlyComplete) this.boletos = this.filteredBoletos;
            else this.boletos = this.allboletos;

            this.$forceUpdate();
          };

          reader.readAsText(file);
        }
      }
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
