<template>
  <v-app id="inspire">
    <v-snackbar v-model="snackbar" top right>
      <h3>{{ text }}</h3>

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-container fluid>
      <br />
      <h2 @click="snackbar = true">Novo documento à pagar</h2>
      <br />
      <br />
      <v-card class="pa-4 ma-4"
        ><v-row class="mt-4">
          <v-col>
            <AutocompleteSelect
              label="Fornecedor"
              :items="providersNames"
              v-model="chipProvider"
            ></AutocompleteSelect>
          </v-col>

          <v-col>
            <AutocompleteSelect
              label="CNPJ"
              :items="federal_docs"
              v-model="chipDoc"
            ></AutocompleteSelect>
          </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-container fluid>
          <h2>Informações</h2>
          <CardCom298
            :new-com298="newCom298"
            :options-com298="oldCom298s"
            :process="process"
            @selectProcess="selectProcess"
          ></CardCom298>
        </v-container>

        <v-container fluid>
          <h2>Despesas do processo</h2>
          <v-card fluid class="pa-10">
            <v-card-text>
              <!-- {{ JSON.stringify({ despesas }) }} -->
              <v-data-table
                hide-default-footer
                :headers="headersDespesas"
                :items="despesas"
                item-key="name"
                class="custom-table"
                ref="dataTable"
                :server-items-length.sync="despesas.length"
              >
                <template v-slot:item.checked="{ item }">
                  <v-icon style="color: green" v-if="item.checked"
                    >mdi-check</v-icon
                  >
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-container>

        <v-container fluid>
          <h2>Ítens</h2>
          <v-card fluid class="pa-10">
            <v-row>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="addProduto">Novo +</v-btn>
            </v-row>
            <v-row v-for="(produto, index) in newCom298Produtos" :key="index">
              <p>{{ index }}</p>
              <CardProdutoCom298
                :produto="produto"
                :index="index"
                :options-produtos="oldCom298sProdutos"
                :process="process"
                :provider="provider"
                @deleteMe="deleteItem"
              ></CardProdutoCom298>
            </v-row>

            <v-card-text> </v-card-text>
          </v-card>
        </v-container>
      </v-row>

      <v-row class="pa-4">
        <v-spacer></v-spacer>
        <v-btn @click="cadastroCom298" class="primary">Cadastro</v-btn>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import strapiApi from "../../../../../libraries/strapi/strapiApi";
import { mapState } from "vuex";
import AutocompleteSelect from "../../../components/select/AutocompleteSelect.vue";
import conexosStrapiApi from "../../../../../libraries/conexos/conexosStrapiApi";
import CardProdutoCom298 from "./CardProdutoCom298.vue";
import CardCom298 from "./CardCom298.vue";

export default {
  components: {
    AutocompleteSelect,
    CardProdutoCom298,
    CardCom298,
  },
  name: "NewPayment",
  data() {
    return {
      providers: [],
      provider: {},
      chipProvider: "",
      oldCom298s: [],
      allOldCom298s: [],
      oldCom298sProdutos: [],
      newCom298Produtos: [],
      newCom298: {},
      process: {},
      federal_docs: [],
      chipDoc: "",
      nfse: {},
      text: "Alert",
      snackbar: false,
      despesas: [],
      headersDespesas: [
        {
          text: "Projeto",
          value: "prjCod",
          width: "20px",
          sortable: true,
        },
        {
          text: "Conta de projeto",
          value: "ctpDesNome",
          align: "start",
          sortable: false,
        },
        {
          text: "Valor",
          value: "pidMnyValormn",
          align: "start",
          sortable: false,
        },
        {
          text: "Serviço relacionado",
          value: "checked",
          align: "start",
          sortable: false,
        },
      ],
    };
  },
  props: ["nfseId"],
  watch: {
    chipProvider(val, oldVal) {
      this.getProviderInfo();
    },

    chipDoc(val, oldVal) {
      this.getOldCom298();
    },
  },

  async mounted() {
    await this.getNfse();

    await this.getProviders();

    await this.getDespesasProcesso();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    providersNames() {
      return this.providers?.map((el) => el.dpeNomPessoa.substring(0, 20));
    },
  },
  methods: {
    async getDespesasProcesso() {
      let ans = await conexosStrapiApi.getDespesasProcesso(1735, 1);

      console.log(JSON.stringify({ ans }));

      if (ans?.rows) {
        this.despesas = ans.rows;

        //Checkout if there's a serviço related
        if (this.nfse?.nfse_servicos) {
          for (let i = 0; i < this.nfse?.nfse_servicos.length; i++) {
            let servico = this.nfse?.nfse_servicos[i];
            //alert(JSON.stringify({ servico }));
            this.despesas = this.despesas.map((el) => ({
              ...el,
              checked: el.pidMnyValormn == servico.ValorServico,
            }));
          }
        }
      }
    },

    selectProcess(process) {
      this.process = process;
    },

    deleteItem(index) {
      console.log(JSON.stringify({ index }));
      this.newCom298Produtos.splice(index, 1);
    },

    async addProduto() {
      let produto = {
        description: "",
        com298Produto: {},
        value: 0,
        saved: false,
      };
      this.newCom298Produtos.push(produto);
    },
    async getProviders() {
      let providers = await strapiApi.enterprises.getAllWithCom298();

      this.providers = providers;

      if (this.nfse) {
        let providerName = this.nfse.PrestadorRazaoSocial?.substring(0, 20);
        if (providerName.includes("T V V")) {
          providerName = "TVV TERMINAL DE VILA";
        }
        this.chipProvider = providerName;
        await this.getProviderInfo();
        this.chipDoc = this.nfse.PrestadorCnpj;
        await this.getOldCom298();

        this.newCom298.docEspNumero = this.nfse.Numero;
        this.newCom298.processRef = this.nfse.processRef;
        this.newCom298.docDtaEmissao = this.nfse.DataEmissao?.substring(0, 10);
        this.newCom298.docDtaMovimento = this.nfse.DataEmissao?.substring(
          0,
          10
        );

        //Load products

        if (this.nfse?.nfse_servicos) {
          for (var i = 0; i < this.nfse.nfse_servicos.length; i++) {
            let servico = this.nfse.nfse_servicos[i];
            this.newCom298Produtos.push({
              description: servico.Descricao,
              value: servico.ValorServico * 100,
              id: servico.id,
            });
          }
        }
      }
    },

    async getNfse() {
      if (this.nfseId) {
        let nfse = await strapiApi.nfses.getById(this.nfseId);
        this.nfse = nfse;
        if (this.nfse?.process) {
          this.process = this.nfse?.process;
        }
      }
    },

    async getProviderInfo() {
      if (this.chipProvider) {
        this.provider = await strapiApi.enterprises.getByName(
          this.chipProvider
        );

        if (this.provider) {
          this.federal_docs = this.provider.federal_docs?.map((el) => el.doc);
        }
      }
    },
    async getOldCom298() {
      if (this.provider?.id) {
        let oldCom298s = await strapiApi.com298s.getFromPesCod(
          this.provider?.id,
          this.chipDoc
        );
        //
        //let filterCom298s = com298s;
        this.allOldCom298s = oldCom298s;

        this.filterOldCom298();
      }
    },
    onlyUniqueCom298(value, index, array) {
      return (
        array.findIndex((el) => el.tpdDesNome == value.tpdDesNome) === index
      );
    },

    onlyUniqueComProduto(value, index, array) {
      return (
        array.findIndex((el) => el.ctpDesNome == value.ctpDesNome) === index
      );
    },

    async filterOldCom298() {
      //let filterCom298s = com298s;
      let oldCom298s = this.allOldCom298s;

      oldCom298s = oldCom298s.filter(
        (el) => el.process?.PRI_VLD_TIPO == this.process?.PRI_VLD_TIPO
      );
      let filterOldCom298s = oldCom298s?.filter(this.onlyUniqueCom298);

      this.newCom298.oldCom298 = oldCom298s?.[0];
      //this.newCom298.process = this.nfse?.process;

      console.log("ok here2");
      let oldCom298sProdutos = [];
      for (let i = 0; i < oldCom298s?.length; i++) {
        let oldDoc = oldCom298s[i];
        if (oldDoc?.com_298_produtos?.length > 0) {
          let produtosFilter = oldDoc?.com_298_produtos.filter(
            (el) => el.ungDesNome == "IMPORTAÇÃO" && el.docVldTipo
          );

          if (produtosFilter?.length > 0)
            oldCom298sProdutos = [
              ...oldCom298sProdutos,
              ...produtosFilter.map((el) => ({
                ...el,
                process: oldDoc.process,
              })),
            ];
        }
      }
      oldCom298sProdutos = oldCom298sProdutos?.filter(
        this.onlyUniqueComProduto
      );
      this.oldCom298s = filterOldCom298s;
      this.oldCom298sProdutos = oldCom298sProdutos;
    },
    async cadastroCom298() {
      let oldCom298 = this.newCom298.oldCom298;

      let com298 = {
        ...oldCom298,
        docEspNumero: this.newCom298.docEspNumero, //from nfse
        filCod: this.process.FIL_COD,
        priCod: this.process.PRI_COD,
        docVldTipo: oldCom298.docVldTipo ?? 9,
        //docVldTipoAdto: oldCom298.docVldTipoAdto ?? 0,
        docDtaEmissao: new Date(this.newCom298.docDtaEmissao).getTime(),
        docDtaMovimento:
          new Date(this.newCom298.docDtaMovimento).getTime() -
          30 * 24 * 60 * 60 * 1000,

        docEspDocest: oldCom298.docEspDocest ?? "ISENTO",
        docMnyValor:
          Math.round(
            this.newCom298Produtos.reduce((acc, cur) => acc + cur.value, 0)
          ) / 100,
        answers: {
          COM009_PESSOA_NUMERO_DOCUMENTO: "YES",
        },
      };

      console.log("com298");
      console.log(JSON.stringify({ com298 }));

      let ans = await conexosStrapiApi.com298post({
        ...com298,
        //  endEspZipcod: "88375000",
      });

      console.log(JSON.stringify({ ans }));
      if (ans.error) {
        this.text = JSON.stringify(ans);
        this.snackbar = true;

        return;
      }
      // alert(JSON.stringify({ ans }));
      if (ans?.docCod) {
        let com298Fields = [
          "docCod",
          "priCod",
          "priEspRefcliente",
          "docDtaEmissao",
          "docEspNumero",
          "docVldTipoAdto",
          "tpdDesNome",
          "pesCod",
          "dpeNomPessoa",
          "ufEspSigla",
          "mnyBruto",
          "docMnyValor",
          "vldStatus",
          "docDtaMovimento",
          "pdcDocFederal",
          "pgtDesNome",
          "gerDes",
          "amzDesNome",
          "docVldCopia",
          "docVldPrevisao",
          "fisVldFundapDesc",
          "filCod",
          "docTip",
          "fisVldTipodoc",
          "fisVldNfemitida",
          "fisVldExonerado",
          "docDtaRecebimento",
          "espSerie",
          "tpdCod",
          "dpeCodSeq",
          "docEspDocest",
          "paDesNome",
          "endDesCidade",
          "endDesBairro",
          "endDesLogradouro",
          "endEspNlogradouro",
          "endEspZipInter",
          "endCod",
          "pgtCod",
          "gerNum",
          "amzCod",
          "fisCod",
        ];

        let com298ToStrapi = {};

        for (let i = 0; i < com298Fields.length; i++) {
          console.log(com298Fields[i]);
          console.log(ans[com298Fields[i]]);
          if (ans[com298Fields[i]]) {
            if (
              com298Fields[i] == "docDtaEmissao" ||
              com298Fields[i] == "docDtaMovimento" ||
              com298Fields[i] == "docDtaRecebimento"
            )
              com298ToStrapi[com298Fields[i]] = new Date(
                ans[com298Fields[i]]
              ).toISOString();
            else com298ToStrapi[com298Fields[i]] = ans[com298Fields[i]];
          }
        }

        console.log("com298 on strapi");
        console.log(JSON.stringify({ com298ToStrapi }));
        let ansStrapi = await strapiApi.com298s.post(com298ToStrapi);

        console.log(`Strapi: ${JSON.stringify({ ansStrapi })}`);

        this.newCom298.docCod = ans?.docCod;

        console.log(JSON.stringify({ ans }));

        await this.cadastroCom298Produtos();

        //alert(`Cadastrado:${JSON.stringify({ ans })}`);
        alert(`Cadastrado`);
        this.$forceUpdate();
      }
    },
    async cadastroCom298Produto() {
      let com298produto = {};

      let ans = await conexosStrapiApi.com298comDocProduto(com298produto);

      console.log(JSON.stringify({ ans }));
    },
    async cadastroCom298Produtos() {
      //alert("cadastroCom298Produtos");
      for (let i = 0; i < this.newCom298Produtos.length; i++) {
        let newCom298produto = this.newCom298Produtos[i];
        let com298Produto = {
          // "filCod": 3,
          // "docCod": 196199,
          filCod: this.process.FIL_COD, //from process
          priCod: this.process.PRI_COD,
          docCod: this.newCom298.docCod, //from com298

          docTip: newCom298produto?.refCom298Produto?.docTip, //2?
          fisCod: newCom298produto?.refCom298Produto?.fisCod, //1?

          docVldTipo: newCom298produto?.refCom298Produto?.docVldTipo, //9
          prdCod: newCom298produto?.refCom298Produto?.prdCod, //56?
          tpcCod: newCom298produto?.refCom298Produto?.tpcCod, //138

          dprQtdQuantidade:
            newCom298produto?.refCom298Produto?.dprQtdQuantidade, //1?

          undCod: newCom298produto?.refCom298Produto?.undCod, //4?

          dprVldOrigMerc: newCom298produto?.refCom298Produto?.dprVldOrigMerc, //1?
          // qualifier: "FINANCEIRO_PAGAR",

          cfoEspCod: newCom298produto?.refCom298Produto?.cfoEspCod, //999901 - list Options?

          prjCod: newCom298produto?.refCom298Produto?.prjCod, //2- Try to hide , its important

          ctpCod: newCom298produto?.refCom298Produto?.ctpCod, //From ContasProjetoCtb
          ctpDesNome: newCom298produto?.refCom298Produto?.ctpDesNome, //From ContasProjetoCtb

          ccuCod: newCom298produto?.refCom298Produto?.ccuCod, //from CENTRO DE CUSTOS
          ccuDesNome: newCom298produto?.refCom298Produto?.ccuDesNome, //CENTRO CUSTOS

          dprPreValorun: Math.round(newCom298produto.value) / 100, //From input
          dpeCodSeq: 1,
        };

        // //PROEJTO
        //"ctpCod": 1068,
        //"ctpEspConta": "102172",
        //"ctpDesNome": "PACOTE LOGÍSTICO",
        //"prjEspMascara": "9.99.999"

        //CENTRO CUSTOS
        //"ccuCod": 73643614,
        //"ccuDesNome": "NÃO OPERACIONAIS"
        //"filCod": 3,
        //"ccuEspConta": "1000",

        if (com298Produto.id) delete com298Produto["id"];

        console.log(JSON.stringify({ com298Produto }));

        let ans = await conexosStrapiApi.com298comDocProduto(com298Produto);
        this.newCom298Produtos[i].saved = true;

        console.log(`Ans Com298Produto`);
        console.log(JSON.stringify({ ans }));

        if (ans?.docCod) {
          //Store on backend

          let ansStrapi = await strapiApi.com298s.postProduto({
            filCod: ans.filCod,
            docTip: ans.docTip,
            docCod: ans.docCod,
            fisCod: ans.fisCod,
            prdCod: ans.prdCod,
            dprCodSeq: ans.dprCodSeq,
            prjCod: ans.prjCod,
            ctpCod: ans.ctpCod,
            dprPreValorun: ans.dprPreValorun,
            priCod: ans.priCod,
            vldPermiteArmaz: ans.vldPermiteArmaz,
            vldPermiteApto: ans.vldPermiteApto,
            ctpDesNome: ans.ctpDesNome,
            ccuDesNome: ans.ccuDesNome,
            ungDesNome: ans.ungDesNome,
            ccuEspConta: ans.ccuEspConta,
            docVldTipo: ans.docVldTipo,
            tpcCod: ans.tpcCod,
            cfoEspCod: ans.cfoEspCod,
            dprQtdQuantidade: ans.dprQtdQuantidade,
            undCod: ans.undCod,
            dprVldOrigMerc: ans.dprVldOrigMerc,
            qualifier: ans.qualifier,
            ccuCod: ans.ccuCod,
            ungCod: ans.ungCod,
          });

          console.log(JSON.stringify({ ansStrapi }));

          this.createServiceProductMap(newCom298produto, ans);
        }
      }
    },
    async createServiceProductMap(newCom298produto, ans) {
      let data = {
        serviceDescription: newCom298produto.description,
        productDescription: newCom298produto.refCom298Produto?.ctpDesNome,
        provider: this.provider?.id,
        priVldTipo: this.process?.PRI_VLD_TIPO,
        com_298_produto: ans.docCod * 100 + ans.dprCodSeq,
        nfse_servico: newCom298produto.id,
      };

      console.log(`store serviceProdut map`);

      console.log(JSON.stringify(data));

      let res = await strapiApi.serviceProductMaps.post(data);

      //alert(JSON.stringify(res));
    },
  },
};
</script>

<style></style>
