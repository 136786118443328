import Vue from "vue";
import Router from "vue-router";

import Dashboard from "@/views/dashboard/pages/dashboard/Dashboard";

import Process from "@/views/dashboard/pages/processes/Process";
import Account from "@/views/dashboard/pages/user/Account";

import Construction from "@/views/dashboard/pages/info/Construction";
import Sla from "@/views/dashboard/pages/sla/Sla";
import Alerts from "@/views/dashboard/pages/alerts/Alerts";
import Finance from "@/views/dashboard/pages/finance/Finance";
import Offers from "@/views/dashboard/pages/offers/Offers";
import Acquisitions from "@/views/dashboard/pages/acquisitions/Acquisitions";
import OffersUpdates from "@/views/dashboard/pages/offers/OffersUpdates";

import Installments from "@/views/dashboard/pages/installments/Installments";
import store from "./store";
import NewPayment from "@/views/dashboard/pages/payments/com342/NewPayment";
import NewPaymentCom298 from "@/views/dashboard/pages/payments/com298/NewPaymentCom298";
import NewPaymentCom298FromNfse from "@/views/dashboard/pages/payments/com298/NewPaymentCom298FromNfse";
import Boletos from "@/views/dashboard/pages/boletos/Boletos";
import BoletosAdmin from "@/views/dashboard/pages/boletos/BoletosAdmin";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/login",
      name: "Login",
      meta: {
        title: "Login",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Login.vue"
        ),
    },
    {
      path: "/register",
      name: "Register",
      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/Register.vue"
        ),
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",

      meta: {
        title: "Register",
      },
      component: () =>
        import(
          /* webpackChunkName: "login" */ "@/views/dashboard/pages/login/ResetPassword.vue"
        ),
    },

    {
      path: "/",
      redirect: { path: "/dashboard" },
      component: () => import("@/views/dashboard/Index"),

      children: [
        {
          path: "/account",
          name: "Account",
          component: Account,
        },

        {
          path: "/Offers",
          name: "Offers",
          component: Offers,
        },
        {
          path: "/Acquisitions",
          name: "Acquisitions",
          component: Acquisitions,
        },
        {
          path: "/offers-updates",
          name: "OffersUpdates",
          component: OffersUpdates,
        },
        {
          path: "/installments",
          name: "Installments",
          component: Installments,
        },

        {
          path: "/newPayment",
          name: "NewPayment",
          component: NewPayment,
        },
        {
          path: "/newPaymentCom298",
          name: "NewPaymentCom298",
          //props: true,
          component: NewPaymentCom298,
        },

        {
          path: "/finance",
          name: "Finance",
          props: true,
          component: Finance,
        },

        {
          path: "/boletos",
          name: "Boletos",
          props: true,
          component: Boletos,
        },

        {
          path: "/boletosAdmin",
          name: "Boletos admin",
          props: true,
          component: BoletosAdmin,
        },
        {
          path: "/newPaymentCom298FromNfse/:nfseId",
          name: "newPaymentCom298FromNfse",
          props: true,
          component: NewPaymentCom298FromNfse,
        },

        {
          name: "/#",
          path: "/:pathMatch(.*)*",
          //component: () => import("./views/dashboard/pages/NotFound"),
          component: Installments,
        },
      ],
    },
  ],
});
