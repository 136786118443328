import functions from "./functions";
var nfses = {
  async getWithPagination(page, pageSize, sort, sortDesc, providerName, docs) {
    console.log(JSON.stringify({ docs }));
    const start = Date.now();

    var filters = {};

    if (providerName) {
      filters = {
        or: [
          {
            PrestadorRazaoSocial: {
              contains: providerName,
            },
          },
        ],
      };
    }

    if (docs && docs.length > 0) {
      filters.or.push({
        PrestadorCnpj: {
          in: docs,
        },
      });
    }

    console.log(JSON.stringify({ filters }));

    var query = `query nfses($filters:NfseFiltersInput!){
      nfses( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination nfses time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.nfses?.meta?.pagination;
    const values = functions.sanitize(res.data.nfses.data);
    return { values, pagination };
  },
  async getById(nfseId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/nfses?filters[id]=${nfseId}&populate=nfse_servicos,process`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res.data);

    if (values && values.length > 0) return values[0];
    else return null;
  },

  async getByFilters(page, pageSize, sort, sortDesc, filters) {
    const start = Date.now();

    // var filters = {};

    // if (providerName) {
    //   filters = {
    //     or: [
    //       {
    //         PrestadorRazaoSocial: {
    //           contains: providerName,
    //         },
    //       },
    //     ],
    //   };
    // }
    var query = `query nfses($filters:NfseFiltersInput!){
      nfses( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    // console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    // console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination nfses time: ${(end - start) / 1000} s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.nfses?.meta?.pagination;
    const values = functions.sanitize(res.data.nfses.data);
    return { values, pagination };
  },
};

export default nfses;
