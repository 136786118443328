<template>
  <v-container fluid class="ma-0 pa-2">
    <div
      style="height: 550px; margin: 0px"
      id="mapContainer"
      ref="mapContainer"
    ></div>

    <div class="mt-5">
      <table style="border-collapse: collapse; width: 100%">
        <tr>
          <th>País</th>
          <th>Valor</th>
        </tr>

        <tr v-for="(legend, index) in legends" :key="index">
          <td>
            <span
              style="
                margin-top: 2px;
                height: 15px;
                width: 15px;
                background-color: #bbb;
                border-radius: 50%;
                display: inline-block;
              "
              :style="{ backgroundColor: legend.color }"
            ></span>
            <span>{{ legend.name }}</span>
          </td>
          <td style="font-size: 12px">
            (<span v-decimal-format="legend?.total"></span>
            USD)
          </td>
        </tr>
      </table>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import mapboxgl from "mapbox-gl";
import { MapboxMap } from "mapbox-gl";
//  //date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
export default {
  name: "MapVessel",
  props: [],
  emits: [],
  data() {
    return {
      //   marker,
      legends: [],
      mapInstance: {},
      mylayers: [],
    };
  },
  computed: {
    ...mapState(["countriesAcc"]),
    layers() {
      var style = this.mapInstance.getStyle();
      var allLayers = style.layers;
      return allLayers;
      //return this.mapInstance.layers;
    },

    maximunValue() {
      return Math.max(
        ...(this.countriesAcc && this.countriesAcc.map((el) => el.total))
      );
    },
    mininumValue() {
      return Math.min(
        ...(this.countriesAcc && this.countriesAcc.map((el) => el.total))
      );
    },
  },
  watch: {
    countriesAcc(val, oldVal) {
      let layersToRemove = this.layers.filter((el) =>
        el?.id?.includes("country-layer")
      );

      //Remove all country layers
      if (layersToRemove)
        layersToRemove.map((el) => {
          this.mapInstance.removeLayer(el.id);
        });
      let countriesAcc = val;
      this.loadLegends(val);

      this.mylayers = [];
      if (countriesAcc) {
        countriesAcc.map((el, index) => {
          if (el?.total && this.maximunValue && el?.code) {
            this.mylayers.push(
              this.getGradientColor(
                (el?.total - this.mininumValue) /
                  (this.maximunValue - this.mininumValue)
              )
            );
            this.mapInstance.addLayer({
              id: `country-layer-${index}`,
              type: "fill",
              source: "countries-no-simplification",
              "source-layer": "country_boundaries",
              filter: [
                "all",
                [
                  "match",
                  ["get", "iso_3166_1"],
                  el?.code ? el?.code : ["BR"],

                  true,
                  false,
                ],
              ],
              paint: {
                "fill-color": this.getGradientColor(
                  (el?.total - this.mininumValue) /
                    (this.maximunValue - this.mininumValue)
                ),
                "fill-opacity": 0.6,
              },
            });
          }
        });
      }
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiY29yZGVpcm9lbmVyZ2lhIiwiYSI6ImNsMTZtbzVidTB2cHgzcWthanV6NG55NjYifQ.R4AVHfoqqWZptRMOdpVtIQ";
    const map = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [18, 10],
      zoom: 1,
    });
    var marker = new mapboxgl.Marker({
      size: "medium",
      icon: "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/iconShipping.png",
      iconSize: [50, 50],
    }).setLngLat([-43.095142, -25.797843]);

    var markerImg = new Image();
    markerImg.src =
      "https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/iconShipping.png";

    marker.setPopup(new mapboxgl.Popup().setHTML("<h1>My Shipping</h1>"));

    var self = this;

    this.mapInstance = map;

    this.loadLegends(this.countriesAcc);

    map.on("load", function () {
      map.addSource("countries-no-simplification", {
        type: "vector",
        url: "mapbox://mapbox.country-boundaries-v1",
      });
      self.mylayers = [];
      if (self && self.countriesAcc) {
        self.countriesAcc.map((el, index) => {
          if (el?.total && self.maximunValue && el?.code) {
            self.mylayers.push(
              self.getGradientColor(
                (el?.total - self.mininumValue) /
                  (self.maximunValue - self.mininumValue)
              )
            );
            map.addLayer({
              id: `country-layer-${index}`,
              type: "fill",
              source: "countries-no-simplification",
              "source-layer": "country_boundaries",
              filter: [
                "all",
                [
                  "match",
                  ["get", "iso_3166_1"],
                  el?.code ? el?.code : ["BR"],

                  true,
                  false,
                ],
              ],
              paint: {
                "fill-color": self.getGradientColor(
                  (el?.total - self.mininumValue) /
                    (self.maximunValue - self.mininumValue)
                ),
                "fill-opacity": 0.6,
              },
            });
          }
        });
      }
    });
  },
  created() {},
  methods: {
    loadLegends(countriesAcc) {
      this.legends = countriesAcc.map((el) => {
        return {
          color: this.getGradientColor(
            (el?.total - this.mininumValue) /
              (this.maximunValue - this.mininumValue)
          ),
          min: this.mininumValue,
          max: this.maximunValue,
          total: el?.total,
          value:
            (el?.total - this.mininumValue) /
            (this.maximunValue - this.mininumValue),
          ...el,
        };
      });
      this.legends.sort((a, b) => b?.total - a?.total);
    },

    getGradientColor(value) {
      if (isNaN(value)) {
        return "#171B66";
      }

      var startColor = [122, 198, 255];
      var endColor = [23, 27, 102];

      var r = Math.round(startColor[0] + (endColor[0] - startColor[0]) * value);
      var g = Math.round(startColor[1] + (endColor[1] - startColor[1]) * value);
      var b = Math.round(startColor[2] + (endColor[2] - startColor[2]) * value);

      var rgb = [r, g, b].map(function (color) {
        var hex = color.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
      });

      return "#" + rgb.join("");
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #eeeeee;
}
</style>
