<template>
  <div>
    <v-dialog v-model="showDialog" width="1800px" class="pa-0 ma-0">
      <v-card style="min-height: 60vh">
        <v-toolbar class="primary" dark
          >Buscar processo
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="closeModal"> x </v-btn></v-toolbar
        >

        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                label="Processo ref"
                placeholder="Referência de processo"
                v-model="search"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                label="FilCod"
                placeholder="Filial"
                v-model.number="filCod"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-btn class="primary" @click="doSearch">Buscar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- <v-card-text>
          {{ JSON.stringify(processes) }}
        </v-card-text> -->

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="processes"
            item-key="name"
            class="custom-table"
            :page.sync="page"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :server-items-length.sync="serverItemsLength"
            :footer-props="{
              'items-per-page-options': [10, 25, 50],
            }"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            @update:items-per-page="updateRowsPerPage"
            @update:page="updatePage"
            ref="dataTable"
          >
            <template v-slot:item.action="{ item }">
              <v-btn class="primary" @click="selectProcess(item)"
                >Selecionar</v-btn
              >
            </template>

            <template v-slot:item.PRI_DTA_ABERTURA="{ item }">
              <span v-date-format="item.PRI_DTA_ABERTURA"></span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import strapiApi from "../../../../../libraries/strapi/strapiApi";

export default {
  name: "search-nfse-dialog",
  props: ["value", "showError", "processRef", "oldCom298"],
  components: {},
  data() {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      search: null,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 15,
      processes: [],
      filCod: 1,
      // headers: [
      //   {
      //     text: "Id",
      //     value: "id",
      //     width: "20px",
      //     sortable: true,
      //   },
      //   {
      //     text: "Prestador",
      //     value: "PrestadorRazaoSocial",
      //     align: "start",
      //     sortable: false,
      //   },
      //   {
      //     text: "Data emissão",
      //     value: "DataEmissao",
      //     align: "start",
      //     sortable: false,
      //   },

      //   { text: "Ref", value: "processRef", sortable: false },

      //   {
      //     text: "Valor Serviços",
      //     value: "ServicoValorServicos",
      //     sortable: false,
      //   },
      //   { text: "Número", value: "Numero", sortable: false },
      //   { text: "Ação", value: "action", sortable: false },
      // ],

      headers: [
        {
          text: "Código",
          value: "PRI_COD",
          width: "20px",
          sortable: true,
        },
        {
          text: "Filial",
          value: "FIL_COD",
          align: "start",
          sortable: false,
        },
        {
          text: "Referência",
          value: "PRI_ESP_REFCLIENTE",
          align: "start",
          sortable: false,
        },
        {
          text: "Data de abertura",
          value: "PRI_DTA_ABERTURA",
          align: "start",
          sortable: false,
        },
        //{ text: "Pdf link", value: "pdfLink", sortable: false },

        {
          text: "Nome pessoa",
          value: "DPE_NOM_PESSOA_LIG",
          sortable: false,
        },

        {
          text: "Selecionar",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    showDialog() {
      //alert("changed");
      this.search = this.processRef;
      this.doSearch();
    },
  },
  async mounted() {
    //alert(this.processRef);
    // this.search = this.processRef;
    // await this.doSearch();
    this.search = this.processRef;

    await this.doSearch();
  },
  methods: {
    selectProcess(process) {
      this.$emit("selectProcess", process);

      this.showDialog = false;
    },

    closeError() {
      this.$emit("closeError");
    },
    closeModal() {
      this.showDialog = false;
    },

    yes() {
      if (this.containers[0] && this.containers[0].containerNumber)
        this.$emit("yes", {
          containerNumber:
            this.containers[0] && this.containers[0].containerNumber,
          blContainersRef: this.blContainersRef ? this.blContainersRef : "",
        });
      else alert("Informar container e BL");
    },
    async updateSortBy() {
      //alert("updateSortBy");
      await this.doSearch();
    },

    async updateSortDesc() {
      //alert("updateSortDesc");
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },
    async doSearch() {
      console.log("do search");
      const { values, pagination } =
        await strapiApi.processes.getByRefWithPagination(
          this.page,
          this.itemsPerPage,
          this.sortBy,
          this.sortDesc,
          this.search,
          parseInt(this.filCod)
        );

      console.log(JSON.stringify({ values, pagination }));
      this.processes = values;
      //alert(JSON.stringify({  pagination }));
      this.serverItemsLength = pagination && pagination.total;
    },
  },
};
</script>
