import functions from "./functions";

let serviceProductMaps = {
  async post(serviceProductMap) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/service-product-maps`;
    let data = serviceProductMap;

    console.log(JSON.stringify({ url }));
    console.log(JSON.stringify({ data }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },
};

export default serviceProductMaps;
