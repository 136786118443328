<template>
  <v-container fluid>
    <v-row style="align-items: flex-start">
      <v-col>
        <v-btn @click="deleteMe">-</v-btn>
      </v-col>
      <v-col>
        <v-text-field
          v-model="item.description"
          label="Descrição"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col>
        <!-- <v-text-field
          v-model="item.description"
          label="Descrição"
          color="primary"
        ></v-text-field> -->
        <v-select
          :items="itemsSelect"
          label="Produto"
          v-model="item.configItem"
        ></v-select>
      </v-col>

      <v-col>
        <div>
          <p style="font-size: 8px">Valor</p>
          <input
            style="border-style: none none solid none"
            v-model="value"
            label="Enter Number"
          />
        </div>
      </v-col>
      <v-col>
        <p v-if="item.saved" style="color: green">Cadastrado</p>
      </v-col>
    </v-row>
    <!-- <v-toolbar class="primary" dark> Histórico de rastreios</v-toolbar> -->
    <!-- {{ optionsItems }} -->
  </v-container>
</template>

<script>
export default {
  name: "CardProduto",
  props: ["optionsItems", "item", "index"],
  data() {
    return {
      formattedNumber: "",
      numericInput: "",
    };
  },
  components: {},
  computed: {
    // ...mapState({
    //   user: (state) => state.auth.user,
    // }),
    value: {
      get() {
        return new Intl.NumberFormat("pt-BR", {
          currency: "BRL",

          minimumFractionDigits: 2,
        }).format(this.item.value / 100 || 0);
      },
      set(value) {
        this.item.value = parseInt(value.replace(/[^0-9]/g, ""));
      },
    },

    itemsSelect() {
      return this.optionsItems?.map((el) => ({
        value: el,
        text: el.ctpDesNome,
      }));
    },
  },
  async mounted() {
    //  await this.doSearch();
    //alert("mounted");
  },
  methods: {
    deleteMe() {
      this.$emit("deleteMe", this.index);
    },
    handleInput() {
      // alert("handleinput");
      // this.numericInput = this.numericInput.replace(/[^0-9.]/g, "");
    },
  },
};
</script>

<style></style>
