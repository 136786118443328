import functions from "./functions";
var kanastraAcquisitions = {
  async put(kanastraAcquisition) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra-acquisitions/${kanastraAcquisition.id}`;
    let data = kanastraAcquisition;
    var res = await fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },

  async post(kanastraAcquisition) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra-acquisitions`;
    let data = kanastraAcquisition;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data }),
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    if (res.data) {
      return functions.sanitize(res.data);
    }

    return res;
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    kanastraSent,
    sellerId,
    sponsorName,
    invoiceNumber,
    disabled,
    kanastraCreatedAtFrom,
    createdAtTo
  ) {
    const start = Date.now();

    var filters = {
      disabled: { eq: disabled ?? false },
    };

    if (kanastraSent) {
      filters.kanastraUuid = {
        ne: null,
      };
    }

    if (sellerId) {
      filters.seller = { id: { eq: sellerId } };
    }

    if (sponsorName) {
      filters.sponsorName = {
        containsi: sponsorName,
      };
    }

    if (invoiceNumber) {
      filters.or = [
        {
          kanastraAcquisitionExternalId: {
            containsi: invoiceNumber,
          },
        },
        {
          invoiceNumber: {
            containsi: invoiceNumber,
          },
        },
      ];
    }

    // kanastraCreatedAtFrom ,
    // createdAtTo

    console.log(JSON.stringify({ filters }));

    filters = {};

    filters.and = [];
    if (kanastraCreatedAtFrom) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let createdDateFrom = new Date(kanastraCreatedAtFrom);
      //createdDateFrom.setHours(createdDateFrom.getHours() + 24);
      filters.and.push({
        createdAt: {
          gte: createdDateFrom.toISOString(),
        },
      });
    }
    if (createdAtTo) {
      //alert(`${JSON.stringify({ kanastraCreatedAtFrom })}`);
      let kanastraCreatedDateTo = new Date(createdAtTo);
      kanastraCreatedDateTo.setHours(kanastraCreatedDateTo.getHours() + 24);
      filters.and.push({
        createdAt: {
          lte: kanastraCreatedDateTo.toISOString(),
        },
      });
    }

    var query = `query kanastraAcquisitions($filters:KanastraAcquisitionFiltersInput!){
      kanastraAcquisitions( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          id
          attributes{
            createdAt
            acquisitionId
            totalPrice
            totalAcquisitionPrice
            error
            errorMessage
            seller{
              data{
                id
                attributes{
                  name
                  city
                  state
                }
              }
            }

          }
          
        }
     
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination kanastraAcquisitions time: ${
          (end - start) / 1000
        } s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.kanastraAcquisitions?.meta?.pagination;
    const values = functions.sanitize(res.data.kanastraAcquisitions.data);
    return { values, pagination };
  },
  async getById(nfseId) {
    let url = `${process.env.VUE_APP_STRAPI_URL}/api/kanastra-acquisitions?filters[id]=${nfseId}&populate=nfse_servicos,process`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${window.strapiToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        return res.json();
      })
      .then((json) => {
        console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    const values = functions.sanitize(res.data);

    if (values && values.length > 0) return values[0];
    else return null;
  },

  async getByFilters(page, pageSize, sort, sortDesc, filters) {
    const start = Date.now();

    // var filters = {};

    // if (providerName) {
    //   filters = {
    //     or: [
    //       {
    //         PrestadorRazaoSocial: {
    //           contains: providerName,
    //         },
    //       },
    //     ],
    //   };
    // }
    var query = `query kanastraAcquisitions($filters:NfseFiltersInput!){
      kanastraAcquisitions( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
            id
          attributes{
            TomadorCnpj
            PrestadorRazaoSocial
            DataEmissao
            pdfLink
            ServicoValorServicos
            Numero
            ServicoDiscriminacao
            PrestadorCnpj
            ValorLiquidoNfse
            processRef
            provider{
              data{
                id
                attributes{
                  dpeNomPessoa
                }
              }
            }
            nfse_servicos{
              data{
                id
                attributes{
                  Descricao
                  Quantidade
                  ValorUnitario
                  ValorServico
                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    //if (window.debug)
    // console.log(JSON.stringify({ graphql }));
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    // console.log(JSON.stringify({ res }));
    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution getWithPagination kanastraAcquisitions time: ${
          (end - start) / 1000
        } s`
      );

    console.log("will sanitize");
    const pagination = res?.data?.kanastraAcquisitions?.meta?.pagination;
    const values = functions.sanitize(res.data.kanastraAcquisitions.data);
    return { values, pagination };
  },
};

export default kanastraAcquisitions;
