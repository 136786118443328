import functions from "./functions";
var enterprises = {
  async getOptions() {
    var url = `${process.env.VUE_APP_STRAPI_URL}/api/enterprise/options`;
    var res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      })
      .catch((error) => {
        if (window.debug) console.log("Error - Backend: ", error);
        return error;
      });

    return res;
  },

  async getAll(dateStart, dateEnd, orderers, exporters, filterShipped) {
    const start = Date.now();

    var filters = {
      and: [
        {
          PRI_DTA_ABERTURA: { gte: dateStart.toISOString() },
        },
        {
          PRI_DTA_ABERTURA: { lte: dateEnd.toISOString() },
        },
      ],
    };

    if (orderers && orderers.length > 0) {
      if (orderers.length == 1) {
        filters.and.push({
          DPE_NOM_PESSOA_LIG: { containsi: orderers[0] },
        });
      } else {
        filters.and.push({
          or: orderers.map((el) => ({
            DPE_NOM_PESSOA_LIG: { containsi: el },
          })),
        });
      }
    }

    if (exporters && exporters.length > 0) {
      if (exporters.length == 1) {
        filters.and.push({
          NOME_EXPORTADOR: { containsi: exporters[0] },
        });
      } else {
        filters.and.push({
          or: exporters.map((el) => ({
            NOME_EXPORTADOR: { containsi: el },
          })),
        });
      }
    }

    // if (filterShipped > 0) {
    //   if (filterShipped == 1)
    //     filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
    //   else filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
    // }

    if (filterShipped > 0) {
      //Em transito
      if (filterShipped == 1) {
        filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
        filters.and.push({ DTA_CHEGADA_BL: { eq: null } });
      }
      //A embarcar
      if (filterShipped == 2)
        filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
      //Chegou
      if (filterShipped == 3)
        filters.and.push({ DTA_CHEGADA_BL: { ne: null } });
    }
    var query = `query enterprises($filters:ProcessFiltersInput!){
      enterprises( sort:"PRI_DTA_ABERTURA" filters:$filters ,pagination:{pageSize:10000}){
        data{
          attributes{
            VLR_TOT_INV
            CANAL
            NOME_EXPORTADOR
            PRI_DTA_ABERTURA
            DPE_NOM_PESSOA_LIG
            PRI_ESP_REFCLIENTE
            DI
            REGISTRO_DI
            PRI_COD
            FIL_COD
            DTA_EMBARQUE_BL
            FRETE_DOLAR_BL
            VALOR_FOB
            ARMAZEM_BL
            PAIS_ORIGEM
            invoices{
              data{
                attributes{
                  INC_ESP_SIGLA
                  items{
                    data{
                      attributes{
                        PRD_DES_NOME
                        VLR_FINAL
                      }
                    }
                  }
                }
              }
            }
            transports{
              data{
                id
                 attributes{
                  ARMADOR
                  CNT_DTA_PREV_EMB
                  CNT_DTA_EMBARQUE
                  PREVISAO_CHEGADA
                  CNT_DTA_CHEGADA
                  containers{
                    data{
                      id

                    }
                  }

                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    console.log(
      `Execution gettAll enterprises time: ${(end - start) / 1000} s`
    );

    return functions.sanitize(res.data.enterprises.data);
  },

  async getAllWithCom298() {
    let filters = {
      com_298s: { id: { ne: null } },
    };
    var query = `
                query enterprises($filters: EnterpriseFiltersInput) {
                  enterprises(pagination: { pageSize: 200 }, filters: $filters) {
                    data {
                      id
                      attributes {
                        dpeNomPessoa
                        addresses {
                          data {
                            attributes {
                              endCod
                              pdcDocFederal
                            }
                          }
                        }
                        federal_docs {
                          data {
                            id
                            attributes {
                              doc
                            }
                          }
                        }
                        com_298s {
                          data {
                            id
                            attributes {
                              dpeNomPessoa
                            }
                          }
                        }
                      }
                    }
                  }
                }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    return functions.sanitize(res.data.enterprises.data);
  },

  async getWithPagination(
    page,
    pageSize,
    sort,
    sortDesc,
    filterName,
    dateStart,
    dateEnd,
    orderers,
    exporters,
    filterShipped
  ) {
    const start = Date.now();

    var filters = {
      and: [
        {
          PRI_DTA_ABERTURA: { gte: dateStart.toISOString() },
        },
        {
          PRI_DTA_ABERTURA: { lte: dateEnd.toISOString() },
        },
      ],
    };

    if (orderers && orderers.length > 0) {
      if (orderers.length == 1) {
        filters.and.push({
          DPE_NOM_PESSOA_LIG: { containsi: orderers[0] },
        });
      } else {
        filters.and.push({
          or: orderers.map((el) => ({
            DPE_NOM_PESSOA_LIG: { containsi: el },
          })),
        });
      }
    }

    if (exporters && exporters.length > 0) {
      if (exporters.length == 1) {
        filters.and.push({
          NOME_EXPORTADOR: { containsi: exporters[0] },
        });
      } else {
        filters.and.push({
          or: exporters.map((el) => ({
            NOME_EXPORTADOR: { containsi: el },
          })),
        });
      }
    }

    // if (filterShipped > 0) {
    //   if (filterShipped == 1)
    //     filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
    //   else filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
    // }

    if (filterShipped > 0) {
      //Em transito
      if (filterShipped == 1) {
        filters.and.push({ DTA_EMBARQUE_BL: { ne: null } });
        filters.and.push({ DTA_CHEGADA_BL: { eq: null } });
      }
      //A embarcar
      if (filterShipped == 2)
        filters.and.push({ DTA_EMBARQUE_BL: { eq: null } });
      //Chegou
      if (filterShipped == 3)
        filters.and.push({ DTA_CHEGADA_BL: { ne: null } });
    }

    var query = `query enterprises($filters:ProcessFiltersInput!){
      enterprises( sort:"${sort}${
      sortDesc ? ":desc" : ""
    }" filters:$filters ,pagination:{page:${page},pageSize:${pageSize}}){
        meta{
          pagination{
            total
            pageSize
            page
          }
        }
        data{
          attributes{
            VLR_TOT_INV
            CANAL
            NOME_EXPORTADOR
            PRI_DTA_ABERTURA
            DPE_NOM_PESSOA_LIG
            PRI_ESP_REFCLIENTE
            DI
            REGISTRO_DI
            PRI_COD
            FIL_COD
            DTA_EMBARQUE_BL
            FRETE_DOLAR_BL
            VALOR_FOB
            ARMAZEM_BL
            PAIS_ORIGEM
            invoices{
              data{
                attributes{
                  INC_ESP_SIGLA
                  items{
                    data{
                      attributes{
                        PRD_DES_NOME
                        VLR_FINAL
                      }
                    }
                  }
                }
              }
            }
            transports{
              data{
                id
                 attributes{
                  ARMADOR
                  CNT_DTA_PREV_EMB
                  CNT_DTA_EMBARQUE
                  PREVISAO_CHEGADA
                  CNT_DTA_CHEGADA
                  containers{
                    data{
                      id

                    }
                  }

                }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    console.log(
      `Execution getWithPagination enterprises time: ${(end - start) / 1000} s`
    );

    const pagination = res?.data?.enterprises?.meta?.pagination;
    const values = functions.sanitize(res.data.enterprises.data);
    console.log(JSON.stringify({ values }));
    return { values, pagination };
  },

  async getByName(name) {
    const start = Date.now();

    var filters = {
      dpeNomPessoa: { contains: name },
    };

    var query = `query enterprises($filters:EnterpriseFiltersInput!){
      enterprises( filters:$filters ,pagination:{pageSize:1}){
        data{
          id     
          attributes{
            dpeNomPessoa
            users{
              data{
                attributes{
                  email
                }
              }
            }
            addresses{
              data{
                  attributes{
                      endCod
                      pdcDocFederal

                  }
              }
            }
            federal_docs{
              data{
                  id
                  attributes{
                      doc
                  }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution gettAll enterprises time: ${(end - start) / 1000} s`
      );
    if (window.debug) console.log(JSON.stringify(res.data.enterprises));
    const enterprises = functions.sanitize(res.data.enterprises.data);
    if (enterprises.length > 0) return enterprises[0];
    else return {};
  },

  async getByDoc(doc) {
    const start = Date.now();

    var filters = {
      federal_docs: { doc: { eq: doc } },
    };

    var query = `query enterprises($filters:EnterpriseFiltersInput!){
      enterprises( filters:$filters ,pagination:{pageSize:1}){
        data{
          id     
          attributes{
            dpeNomPessoa
            users{
              data{
                attributes{
                  email
                }
              }
            }
            addresses{
              data{
                  attributes{
                      endCod
                      pdcDocFederal

                  }
              }
            }
            federal_docs{
              data{
                  id
                  attributes{
                      doc
                  }
              }
            }
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {
        filters,
      },
    });

    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });

    const end = Date.now();
    if (window.debug)
      console.log(
        `Execution gettAll enterprises time: ${(end - start) / 1000} s`
      );
    if (window.debug) console.log(JSON.stringify(res.data.enterprises));
    const enterprises = functions.sanitize(res.data.enterprises.data);
    if (enterprises.length > 0) return enterprises[0];
    else return {};
  },

  async getExporters(dateStart, dateEnd, orderers) {
    if (window.debug) console.log(JSON.stringify({ orderers }));

    var filters = `{ and:[{PRI_DTA_ABERTURA:{gte: "${dateStart.toISOString()}"  }} , {PRI_DTA_ABERTURA:{lte:"${dateEnd.toISOString()}" }}, {NOME_EXPORTADOR:{ne:null}} `;

    //var filters = `{ and:[{NOME_EXPORTADOR:{ne:null}} `;
    if (orderers && orderers.length > 0) {
      //Insert filter enconmendante
      if (orderers.length > 1) {
        filters += `, {or:[   `;
        for (var a = 0; a < orderers.length; a++) {
          const encomendante = orderers[a];
          if (a == 0)
            filters += `{DPE_NOM_PESSOA_LIG:{containsi:"${encomendante}" }}`;
          else
            filters += `,{DPE_NOM_PESSOA_LIG:{containsi:"${encomendante}" }}`;
        }
        filters += `]}`;
      } else {
        filters += `,{DPE_NOM_PESSOA_LIG:{containsi:"${orderers[0]}" }}`;
      }
    }

    filters += `]}`;

    if (window.debug) console.log(JSON.stringify({ filters }));
    var query = `query enterprises{
      enterprises( filters:${filters} ,pagination:{pageSize:10000}){
        data{
          attributes{
            NOME_EXPORTADOR
          }
        }
      }
    }
    `;

    var graphql = JSON.stringify({
      query,
      variables: {},
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });
    const enterprises = functions.sanitize(
      res && res.data && res.data.enterprises && res.data.enterprises.data
    );

    return (
      enterprises &&
      enterprises
        .filter(
          (arr, index, self) =>
            index ===
            self.findIndex((t) => t.NOME_EXPORTADOR === arr.NOME_EXPORTADOR)
        )
        .map((el) => el.NOME_EXPORTADOR)
    );
  },

  async getCountries() {
    var filters = `{PAIS_ORIGEM:{ne:null}} `;
    var query = `query enterprises{
      enterprises( filters:${filters} ,pagination:{pageSize:10000}){
        data{
          attributes{
            PAIS_ORIGEM
          }
        }
      }
    }
    `;
    if (window.debug) console.log(JSON.stringify({ query }));
    var graphql = JSON.stringify({
      query,
      variables: {},
    });
    var url = `${process.env.VUE_APP_STRAPI_URL}/graphql`;
    if (window.debug) console.log(JSON.stringify({ url }));
    var res = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
        "Content-Type": "application/json",
      },
      body: graphql,
    })
      .then((res) => {
        if (window.debug) console.log(res);
        return res.json();
      })
      .then((json) => {
        if (window.debug) console.log(json);
        return json;
      });
    const enterprises = functions.sanitize(
      res && res.data && res.data.enterprises && res.data.enterprises.data
    );

    return (
      enterprises &&
      enterprises
        .filter(
          (arr, index, self) =>
            index === self.findIndex((t) => t.PAIS_ORIGEM === arr.PAIS_ORIGEM)
        )
        .map((el) => el.PAIS_ORIGEM)
    );
  },
};

export default enterprises;
