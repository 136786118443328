<template>
  <v-container fluid>
    <v-container v-if="user?.role?.id == 6">
      <p>Processos em alerta</p>
    </v-container>
    <v-container v-else>
      <p>Acesso não autorizado</p>
    </v-container>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";

export default {
  name: "Process",
  props: ["priCod"],
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    filCod() {
      return this.$route.query.filCod;
    },
  },
  data() {
    return {
      processes: [],
    };
  },
  async mounted() {
    this.getProcesses();
  },
  methods: {
    async getProcesses() {
      let processes = await strapiApi.processes.getWarehouseAlerts();
      this.processes = processes;
    },
  },
};
</script>

<style scoped>
.tabActive {
  background-color: #3e8ac7;
}

.holds-the-iframe {
  background: url("https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif")
    center center no-repeat;
}

.custom-table .v-data-table__wrapper {
  border: none;
}

.custom-table .v-data-table__thead > tr > th:not(:last-child),
.custom-table .v-data-table__tbody > tr > td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 1) !important;
}
</style>
