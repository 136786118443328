<template>
  <v-card class="pa-10">
    <SearchProcessDialog
      v-model="showProcessDialog"
      :process-ref="newCom298.processRef"
      @selectProcess="selectProcess"
    ></SearchProcessDialog>

    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            :items="itemsCom298"
            label="Tipo de documento"
            v-model="newCom298.oldCom298"
          ></v-select>
        </v-col>

        <v-col>
          <v-text-field v-model="newCom298.docEspNumero" label="Número da nota">
          </v-text-field>
        </v-col>

        <v-col>
          <v-text-field
            v-model="newCom298.processRef"
            label="Referência na nota"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="my-4">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            class="mt-3"
          >
            <template class="mt-3" v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Data de emissão"
                v-model="newCom298.docDtaEmissao"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                color="actionButton"
                event-color="actionButton"
                style="
                  color: black;
                  font-size: 16px !important;
                  font-weight: 600 !important;
                "
              ></v-text-field>
            </template>
            <v-card class="ma-0 pa-0">
              <v-date-picker
                v-model="newCom298.docDtaEmissao"
                type="date"
                no-title
                @input="menu = false"
                reactive
                scrollable
                color="actionButton"
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
        <v-col class="my-4">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            class="mt-3"
          >
            <template class="mt-3" v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Data de entrada"
                v-model="newCom298.docDtaMovimento"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                color="actionButton"
                event-color="actionButton"
                style="
                  color: black;
                  font-size: 16px !important;
                  font-weight: 600 !important;
                "
              ></v-text-field>
            </template>
            <v-card class="ma-0 pa-0">
              <v-date-picker
                v-model="newCom298.docDtaMovimento"
                type="date"
                no-title
                @input="menu2 = false"
                reactive
                scrollable
                color="actionButton"
              ></v-date-picker>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field disabled v-model="process.PRI_COD" label="Processo">
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field disabled v-model="process.FIL_COD" label="Filial">
          </v-text-field>
        </v-col>
        <v-col>
          <v-btn class="primary" @click="openProcessDialog()">Buscar</v-btn>
        </v-col>
        <!-- <v-col>
          <p v-if="newCom298.docCod" style="color: green">
            {{ newCom298?.docCod }}
          </p>
        </v-col> -->
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import SearchProcessDialog from "./SearchProcessDialog.vue";

export default {
  name: "CardCom298",
  props: ["optionsCom298", "newCom298", "process"],
  data() {
    return {
      menu: false,
      menu2: false,
      formattedNumber: "",
      numericInput: "",
      showProcessDialog: false,
    };
  },
  components: {
    SearchProcessDialog,
  },
  computed: {
    itemsCom298() {
      return this.optionsCom298?.map((el) => ({
        value: el,
        text: el.tpdDesNome,
      }));
    },
  },
  async mounted() {},
  methods: {
    handleInput() {},
    openProcessDialog() {
      console.log("dialog");
      this.showProcessDialog = true;
    },

    selectProcess(process) {
      // this.newCom298.priCod = process.PRI_COD;
      // this.newCom298.filCod = process.FIL_COD;
      this.$emit("selectProcess", process);
    },
  },
};
</script>

<style></style>
