<template>
  <v-dialog v-model="showDialog" width="600px">
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="orimary" text @click="showDialog = false"> Fechar </v-btn>
      </v-card-actions>
      <v-card-title>
        <span class="headline"
          >Alterar parcelas da nota {{ offer?.invoiceNumber }}</span
        >
      </v-card-title>

      <v-card-text class="mt-4">
        <v-simple-table>
          <thead>
            <tr>
              <th></th>
              <th style="width: 10px" class="">Parcela</th>
              <th class="">Valor (R$)</th>

              <th class="">Vencimento</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(installment, index) in installments" :key="index">
              <td>
                <v-icon @click="deleteInstallment(index)" style="color: red"
                  >mdi-delete</v-icon
                >
              </td>
              <td>{{ index }}</td>
              <td>
                <v-text-field
                  v-model="installment.amountStr"
                  v-currency
                  currency="BRL"
                  locale="pt-BR"
                >
                </v-text-field>
              </td>
              <td class="pt-3">
                <DateInput
                  label="asdas"
                  v-model="installment.dueDate"
                ></DateInput>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row class="mt-4">
          <v-spacer></v-spacer>
          <v-btn class="primary" @click="addInstallment"
            >+ Adicionar parcela</v-btn
          >
          <v-spacer></v-spacer>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row :class="{ colorRed: total !== offer.paymentValue }">
          <v-col>
            <span>Valor total :</span>
            <span v-money-format="total"></span>
          </v-col>

          <v-col>
            Valor original : <span v-money-format="offer.paymentValue"></span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text> </v-card-text>
      <v-card-text>
        <v-row>
          <v-spacer></v-spacer>
          <v-btn :disabled="saving" class="primary" @click="save">
            <v-progress-circular
              v-if="saving"
              indeterminate
              color="primary"
            ></v-progress-circular>
            Salvar</v-btn
          >
        </v-row>
      </v-card-text>
      <p></p>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import kanastraApi from "../../../../libraries/kanastra/kanastraApi";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import dateFunctions from "../../../../libraries/corsolar/dateFunctions";
import DateInput from "../../components/fields/DateInput.vue";

export default {
  name: "CreateInstallmentsDialog",
  props: ["value", "offer"],
  components: {
    AutocompleteSelect,
    DateInput,
  },

  data() {
    return {
      saving: false,
      installments: [{ amountStr: "0", dueDate: "2024-02-20" }],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    total() {
      return this.installments.reduce(
        (acc, curr) => acc + this.parseMoneyToFloat(curr.amountStr),
        0
      );
    },
  },

  watch: {
    value(newVal, oldVal) {
      //alert("changed value" + JSON.stringify({ oldVal, newVal }));
      if (newVal && this.offer?.installments) {
        this.installments = this.offer?.installments.map((el) => ({
          amountStr: el.installmentAmount?.toLocaleString("pt-BR"),
          dueDate: el.installmentDueDate,
        }));
        console.log(
          JSON.stringify(
            this.offer?.installments.map((el) => ({
              amountStr: el.installmentAmount?.toLocaleString("pt-BR"),
              dueDate: el.installmentDueDate,
            }))
          )
        );
      }
    },
  },
  async mounted() {},
  methods: {
    deleteInstallment(index) {
      this.installments.splice(index, 1);
    },
    addInstallment() {
      let d = new Date();
      this.installments.push({
        amountStr: "0",
        dueDate: d.toISOString()?.substring(0, 10),
      });
    },
    parseMoneyToFloat(text) {
      console.log(`ParseFloat:${text}`);
      text = text.replace(/\./g, "");
      text = text.replace(",", ".");
      console.log(`ParseFloat:${text}`);
      return parseFloat(text);
    },
    // parseFloatToMoney(value) {
    //   let text = value.toString();
    //   //console.log(`ParseFloat:${text}`);
    //   text = text.replace(/\./g, "");
    //   text = text.replace(",", ".");
    //   console.log(`ParseFloat:${text}`);
    //   return parseFloat(text);
    // },

    async save() {
      this.saving = true;

      let offer = this.offer;

      let offerExternalId = parseInt(offer.offerExternalId);
      let prefix = Math.floor(offerExternalId / 10 ** 8);

      let docCod =
        offerExternalId - Math.floor(offerExternalId / 10 ** 7) * 10 ** 7;

      let updateNumber = this.offer.updateNumber ?? 0;

      updateNumber = updateNumber + 1;

      // alert(JSON.stringify({ updateNumber }));
      //alert(JSON.stringify({ prefix }));
      //return;
      //Disabled older offer

      //Create new order

      //Create new Installments

      // alert(JSON.stringify({ updateNumber }));
      let offerUpdate = {
        id: offer.id,
        disabled: true,
      };
      let ansUpdate = await strapiApi.offers.put(offerUpdate);
      // alert(JSON.stringify({ ansUpdate }));

      let newOffer = {
        ...offer,
        id: prefix * 10 ** 8 + updateNumber * 10 ** 7 + docCod,

        seller: offer.seller.id,
        com297: offer?.com297?.id,
        kanastraStatus: null,
        kanastraCreatedAt: null,
        kanastraUpdatedAt: null,
        kanastraAcquisitionId: null,
        kanastraUuid: null,
        kanastraStatus: null,
        kanastraCreatedAt: null,
        kanastraUpdatedAt: null,
        kanastraAcquisitionId: null,
        kanastraUuid: null,
        offerExternalId: (
          prefix * 10 ** 8 +
          updateNumber * 10 ** 7 +
          docCod
        ).toString(),
        updateNumber,
        disabled: false,
      };
      // alert(newOffer.id);

      // return;
      delete newOffer.installments;
      let ansCreate = await strapiApi.offers.post(newOffer);
      //alert(JSON.stringify({ ansCreate }));
      //return;

      let totalInstallments = this.installments.length;
      let installmentsCreate = this.installments.map((item, index) => ({
        id:
          prefix * 10 ** 8 +
          updateNumber * 10 ** 7 +
          docCod * 10 ** 2 +
          index +
          1,
        offerExternalId: (
          prefix * 10 ** 8 +
          updateNumber * 10 ** 7 +
          docCod
        ).toString(),
        offer: prefix * 10 ** 8 + updateNumber * 10 ** 7 + docCod,
        seller: offer.seller?.id,
        sponsorName: offer.sponsorName,
        sponsorPersonType: offer.sponsorPersonType,
        sponsorGovernmentId: offer.sponsorGovernmentId,
        sponsorAddress: offer.sponsorAddress,
        sponsorAddressNumber: offer.sponsorAddressNumber,
        sponsorNeighborhood: offer.sponsorNeighborhood,
        sponsorAddressComplement: offer.sponsorAddressComplement,
        sponsorCity: offer.sponsorCity,
        sponsorState: offer.sponsorState,
        sponsorCountry: offer.sponsorCountry,
        sponsorZipCode: offer.sponsorZipCode,
        sponsorBank: offer.sponsorBank,
        sponsorAgency: offer.sponsorAgency,
        sponsorAgencyDigit: offer.sponsorAgencyDigit,
        sponsorAccount: offer.sponsorAccount,
        sponsorAccountDigit: offer.sponsorAccountDigit,
        assetType: offer.assetType,
        invoiceNumber: offer.invoiceNumber,
        invoiceDate: offer.invoiceDate,
        invoiceKey: offer.invoiceKey,
        totalInstallments: totalInstallments,
        paymentValue: this.total,
        paymentDate: offer.paymentDate,
        installmentExternalId: (
          prefix * 10 ** 8 +
          docCod * 10 ** 2 +
          index
        ).toString(),
        installmentDueDate: item.dueDate,
        installmentAmount: this.parseMoneyToFloat(item.amountStr),
      }));

      //post installments

      for (let i = 0; i < installmentsCreate.length; i++) {
        let installment = installmentsCreate[i];
        let ansCreate = await strapiApi.installments.post(installment);
        // alert(JSON.stringify({ ansCreate }));
      }

      console.log(JSON.stringify({ installmentsCreate }));

      this.showDialog = false;
      this.saving = false;
    },
  },
};
</script>

<style scoped>
.colorRed {
  color: red;
  background-color: white;
}
</style>
