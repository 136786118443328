<template>
  <v-app id="inspire">
    <CreateInstallmentsDialog
      v-model="showDialog"
      :offer="selectedOffer"
    ></CreateInstallmentsDialog>
    <v-container fluid style="height: 100vh; width: 100%; margin-top: 50px">
      <v-container fluid>
        <v-row class="ma-2 my-4 flex">
          <h4 style="margin: auto">Buscar notas</h4>
          <v-spacer></v-spacer>
        </v-row>

        <v-row class="mx-5">
          <v-col cols="3" class="">
            <v-select
              :items="sellerNames"
              v-model="filters.chipSeller"
              label="Seller"
            ></v-select>
          </v-col>

          <v-col cols="4">
            <v-text-field
              class="pb-2"
              label="Sponsor"
              v-model="filters.sponsorName"
            ></v-text-field>
          </v-col>
          <v-col cols="3" class="">
            <v-text-field
              class="pb-2"
              label="Número da nota"
              v-model="filters.offerExternalId"
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="">
            <v-btn class="primary" @click="doSearch">Buscar</v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-2 my-4 flex">
          <h4 style="margin: auto">Notas</h4>
          <v-spacer></v-spacer>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="offers"
          item-key="name"
          class="custom-table"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :server-items-length.sync="serverItemsLength"
          :footer-props="{
            'items-per-page-options': [10, 25, 50],
          }"
          @update:sort-by="updateSortBy"
          @update:sort-desc="updateSortDesc"
          @update:items-per-page="updateRowsPerPage"
          @update:page="updatePage"
          ref="dataTable"
        >
          <!-- <template v-slot:[`item.action`]="{ item }">
            <v-btn @click="newPaymentWithNfse(item)" class="primary">+</v-btn>
          </template>

          <template v-slot:item.installmentDueDate="{ item }">
            <span v-date-format="item.installmentDueDate"></span>
          </template>
          <template v-slot:item.installmentAmount="{ item }">
            <span v-money-format="item.installmentAmount"></span>
          </template>

          <template v-slot:item.preCalculatedAcquisitionPrice="{ item }">
            <span v-money-format="item.preCalculatedAcquisitionPrice"></span>
          </template> -->

          <template v-slot:item.status="{ item }">
            {{ item.kanastraStatus }}
          </template>

          <template v-slot:item.seller="{ item }">
            {{ item.seller?.name }}
          </template>
          <template v-slot:item.kanastraCreatedAt="{ item }">
            <span v-date-format="item.kanastraCreatedAt"></span>
          </template>
          <template v-slot:item.kanastraUpdatedAt="{ item }">
            <span v-date-format="item.kanastraUpdatedAt"></span>
          </template>

          <template v-slot:item.paymentValue="{ item }">
            <span v-money-format="item.paymentValue"></span>
          </template>
          <template v-slot:item.paymentDate="{ item }">
            <span v-date-format="item.paymentDate"></span>
          </template>

          <template v-slot:item.create="{ item }">
            <v-btn class="primary" @click="openDialog(item)"
              >Alterar parcelas
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
import kanastraApi from "../../../../libraries/kanastra/kanastraApi";
import AutocompleteSelect from "../../components/select/AutocompleteSelect.vue";
import dateFunctions from "../../../../libraries/corsolar/dateFunctions";
import CreateInstallmentsDialog from "./CreateInstallmentsDialog.vue";

export default {
  name: "offers",
  components: {
    AutocompleteSelect,
    CreateInstallmentsDialog,
  },

  data() {
    return {
      selectedOffer: {},
      showDialog: false,
      files: [],
      jsonData: [],
      headers: ["PrestadorRazaoSocial", "DataEmissao"],
      filters: {
        chipSeller: "",
        offerExternalId: "",
        sponsorName: "",
      },
      headers: [
        //TomadorCnpj
        // {
        //   text: "Id",
        //   align: "start",
        //   sortable: false,
        //   value: "id",
        // },
        {
          text: "Nota fiscal",
          align: "start",
          sortable: false,
          value: "invoiceNumber",
        },
        {
          text: "Seller",
          align: "start",
          sortable: false,
          value: "seller",
        },
        {
          text: "Sponsor",
          align: "start",
          sortable: false,
          value: "sponsorName",
        },
        {
          text: "Sponsor doc",
          align: "start",
          sortable: false,
          value: "sponsorGovernmentId",
        },

        {
          text: "Valor total (R$)",
          align: "start",
          sortable: false,
          value: "paymentValue",
        },
        {
          text: "Alterar parcelas",
          align: "start",
          sortable: false,
          value: "create",
        },

        // {
        //   text: "Data",
        //   align: "start",
        //   sortable: false,
        //   value: "paymentDate",
        // },
      ],

      offers: [],
      sortBy: "id",
      sortDesc: true,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 10,
      providers: [],
      chipProvider: "",
      sellers: [],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    providersNames() {
      return this.providers?.map((el) => el.dpeNomPessoa.substring(0, 20));
    },

    provider() {
      return this.providers?.find((el) =>
        el.dpeNomPessoa.includes(this.chipProvider)
      );
    },
    sellerNames() {
      return this.sellers.map((el) => el.name);
    },
  },

  watch: {
    chipProvider(val, oldVal) {
      this.doSearch();
    },
    showDialog(val, oldVal) {
      if (!val) {
        this.doSearch();
      }
    },
  },
  async mounted() {
    await this.doSearch();

    await this.getSellers();
  },
  methods: {
    openDialog(offer) {
      this.selectedOffer = offer;
      this.showDialog = true;
    },
    async getSellers() {
      let { values, pagination } = await strapiApi.sellers.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc
      );

      // this.sellers = values;

      // this.filters.chipSeller = values?.[0]?.name;

      this.sellers = [{ id: 0, name: "TODOS" }, ...values];
      this.filters.chipSeller = "TODOS";
      //his.sellers.push({ id: 0, name: "TODOS" });
      //this.filters.chipSeller = values?.[0]?.name;
      this.doSearch();
    },

    async kanastraAuth() {
      let ans = await kanastraApi.auth();

      alert(JSON.stringify({ ans }));
    },

    async sendOffers() {
      let seller = this.sellers?.[0];

      let offers = [];

      if (this.offers?.length) {
        offers.push(this.offers[0]);
        offers.push(this.offers[1]);
      }

      let data = {
        sellerName: seller.name,
        sellerPersonType: seller.personType,
        sellerGovernmentId: seller.governmentId,
        sellerExternalCode: seller.name,
        sellerAddress: seller.address,
        sellerAddressNumber: seller.addressNumber,
        sellerAddressComplement: seller.addressComplement,
        sellerNeighborhood: seller.neighborhood,
        sellerCity: seller.city,
        sellerState: seller.state,
        sellerCountry: seller.country,
        sellerZipCode: seller.zipCode,
        sellerBank: seller.bank,
        sellerAgency: seller.agency,
        sellerAgencyDigit: seller.agencyDigit,
        sellerAccount: seller.account,
        sellerAccountDigit: seller.accountDigit,
        coobrigation: false,
        customFields: {},
      };
      // assetType;
      // invoiceNumber;
      // invoiceDate;
      // invoiceKey;
      // totalInstallments;
      // paymentValue;
      // paymentDate;
      // installmentExternalId;
      // installmentDueDate;
      // installmentAmount;

      let items = offers.map((offer) => ({
        externalId: offer.offerExternalId,
        sponsorName: offer.sponsorName,
        sponsorPersonType: offer.sponsorPersonType,
        sponsorGovernmentId: offer.sponsorGovernmentId,
        sponsorExternalCode: offer.sponsorName,
        sponsorAddress: offer.sponsorAddress,
        sponsorAddressNumber: offer.sponsorAddressNumber,
        sponsorAddressComplement: offer.sponsorAddressComplement,
        sponsorNeighborhood: offer.sponsorNeighborhood,
        sponsorCity: offer.sponsorCity,
        sponsorState: offer.sponsorState,
        sponsorCountry: offer.sponsorCountry,
        sponsorZipCode: offer.sponsorZipCode,
        sponsorBank: offer.sponsorBank,
        sponsorAgency: offer.sponsorAgency,
        sponsorAgencyDigit: offer.sponsorAgencyDigit,
        sponsorAccount: offer.sponsorAccount,
        sponsorAccountDigit: offer.sponsorAccountDigit,
        assetType: offer.assetType,
        invoiceNumber: offer.invoiceNumber,
        invoiceDate: offer.invoiceDate?.substring(0, 10).replace(/-/g, ""),
        invoiceKey: offer.invoiceKey,
        totalInstallments: 1,
        // paymentValue: offer.paymentValue,
        // paymentDate: offer.paymentDate,
        paymentValue: offer.installmentAmount,
        paymentDate: offer.installmentDueDate
          ?.substring(0, 10)
          .replace(/-/g, ""),
        customFields: {},
        installments: [
          {
            externalId: offer.installmentExternalId,
            amount: offer.installmentAmount,
            dueDate: offer.installmentDueDate
              ?.substring(0, 10)
              .replace(/-/g, ""),
            customFields: {
              preCalculatedAcquisitionPrice: offer.installmentAmount,
            },
          },
        ],
      }));

      data.items = items;

      console.log(JSON.stringify({ data }));
      //let ans = await kanastraApi.auth();

      //alert(JSON.stringify({ ans }));

      //Montar objetos
    },

    formatDate() {},

    async newPayment() {
      this.$router.push("/newPayment");
    },
    async newPaymentWithNfse(nfse) {
      this.$router.push(`/NewPaymentCom298FromNfse/${nfse?.id}`);
    },
    async updatePage() {
      await this.doSearch();
    },

    async updateSortBy() {
      await this.doSearch();
    },

    async updateSortDesc() {
      await this.doSearch();
    },

    async updatePage() {
      await this.doSearch();
    },

    async updateRowsPerPage() {
      await this.doSearch();
    },

    async doSearch() {
      let seller = this.sellers?.find((el) =>
        el.name.includes(this.filters.chipSeller)
      );

      let { values, pagination } = await strapiApi.offers.getWithPagination(
        this.page,
        this.itemsPerPage,
        this.sortBy,
        this.sortDesc,
        false,
        seller?.id,
        this.filters.sponsorName,
        this.filters.offerExternalId
      );
      let offers = values;

      this.serverItemsLength = pagination?.total;

      this.offers = offers;
    },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
